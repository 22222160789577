import { EntityOTs } from "../../../shared/Domain/EntityOTs";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityLoadQuestion } from "../Domain/EntityLoadQuestion";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseLoadQuestionsCCP {
    private repository: RepositoryMain;
    private IdMomentoCierreControlPreventivo: number;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
        this.IdMomentoCierreControlPreventivo = 5;
    }

    public async exec(OT:EntityOTs): Promise<EntityLoadQuestion> {
        const [AutochequeoTitulos, AutochequeoItems] = await Promise.all([
            this.getAutochequeoTitulos(OT),
            this.getAutochequeoItems(OT)
        ]);

        return ({
            AutoChequeoTipologia: [],
            AutochequeoTitulos,
            AutochequeoItems
        })
    }
    private async getAutochequeoTitulos(OT: EntityOTs) {
        return [{
            _id: "",
            IdTitulo: -1,
            adittionalIdTitle: [-1],
            Titulo: LanguageTranslate().moduloAutoChequeo.form.navBarTextCierreControlPreventivo.toUpperCase(),
            Estado: { "IdEstado": 1, "Estado": "ACTIVO" },
            Actividad: { "IdActividad": -1, "Codigo": "", "Actividad": "" },
            Delegacion: { "IdDelegacion": -1, "Codigo": "", "Delegacion": "" },
            Momento: { "IdMomento": 5, "Momento": "POST JOB" },
            Pais: { "IdPais": -1, "Pais": "", "Codigo": "" },
            Template: "",
            Tipologia: { "IdTipologia": -1, "CodigoTipologia": "", "TipologíaTrabajo": "" },
            Requerimientos: { "Fotografia": 1, "Observacion": 1 },
            Orden: 1,
            CodigoTitulo: ""
        }]
    }

    private async getAutochequeoItems(OT: EntityOTs) {
        const arr = await this.repository.getAutochequeoItems();
        return arr.filter(
          (item) =>
            item.IdActividad === OT.Actividad.IdActividad &&
            item.IdDelegacion === OT.Delegacion.IdDelegacion &&
            item.IdPais === OT.Pais.IdPais &&
            [this.IdMomentoCierreControlPreventivo].includes(item.Momento.IdMomento)
        ).map(row => ({
            ...row,
            OpcionesCierreCP: this.validateAnswers(row.OpcionesCierreCP, row.Disponibilidad)
        }));
    }

    private validateAnswers(opcionesCierreCP: string, disponibilidad: string): string {
        const _disponibilidad: Array<any> = JSON.parse(disponibilidad || '[]') || [];
        return _disponibilidad.length === 0 ? opcionesCierreCP : disponibilidad
    }
}