import { useState } from "react";
import { ElementEmptyList } from "../../../shared/Components/ElementEmptyList";
import { ElementInputFileImage } from "../../../shared/Components/ElementInputFileImage";
import ElementModalCostume from "../../../shared/Components/ElementModalCostume";
import ElementStepperContent from "../../../shared/Components/ElementStepperContent";
import ElementViewImagesCostume from "../../../shared/Components/ElementViewImagesCostume";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityAplica } from "../Domain/EntityAplica";
import { EntityAutoCompleteItem } from "../Domain/EntityAutocompleteItem";
import { EntityDetalleInspeccion } from "../Domain/EntityDetalleInspeccion";
import { EntitySelectAnomaliaInspeccion } from "../Domain/EntitySelectAnomaliaInspeccion";
import { EntitySelectPersonal } from "../Domain/EntitySelectPersonal";
import { EntitySelectResultado } from "../Domain/EntitySelectResultado";
import { EntitySelectTipoInspeccion } from "../Domain/EntitySelectTipoInspeccion";
import { EntitySelectTipoSituacion } from "../Domain/EntitySelectTipoSituacion";
import { EntitySelectNivelGravedad } from "../Domain/EntitySelectNivelGravedad";

import { InputCheck, InputSelect, InputSelectSimple, InputText } from "./../../../shared/Components/ElementInputsCostume";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";

interface IViewTipoInspeccion {
    keyTab: number;
    onChangeContentForTab: (step: number) => any;
    onChange: (name: string, value: any) => any;
    ListTipoInspeccion: EntitySelectTipoInspeccion[];
    formRegistro: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
    };
    disabled?: boolean;
}

export const ViewTipoInspeccion = (props: IViewTipoInspeccion) => {
    const [showModal, setModal] = useState(false);
    const { moduloInspeccion: { form: { stepper: { stepper4 } } } } = LanguageTranslate();
    const TipoInspeccionSelecteds: EntitySelectTipoInspeccion[] = props.formRegistro.values["TipoInspeccion"] || [];

    return (
        <>
            <ElementModalCostume
                show={showModal}
                closeModal={() => setModal(!showModal)}
                title={stepper4.content.details.titleModal}
            >
                {
                    props.ListTipoInspeccion?.map(inp => {
                        const isSelected = TipoInspeccionSelecteds.find(inspSelected => inspSelected.value === inp.value)
                        return (
                            <div key={inp.value} className='d-flex align-items-center border-bottom my-3 py-2 px-3' style={{ minHeight: 72, background: isSelected ? '#d5d5d5' : '#fff' }} onClick={() => props.onChange('TipoInspeccionSelect', inp)}>
                                <i className='fa-regular fa-address-book' style={{ fontSize: 20, marginRight: 14 }} />
                                <div className='text-align-center'>
                                    <label className='text-primary'>{inp.label}</label>
                                    <p className='mb-0 text-muted'>{stepper4.content.textoCompruebaInformacion} {inp.label}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </ElementModalCostume>
            <ElementStepperContent
                step={4}
                currentStep={props.keyTab}
                title={stepper4.content.details.title}
                onSubmit={props.onChangeContentForTab}
                removeButtonSubmit
                buttonRightCustom={() =>
                    <>
                        {
                            props.disabled ?
                                null
                                :
                                <button onClick={() => setModal(true)} className='btn btn-primary' style={{ height: 38, display: 'flex', alignItems: 'center' }}>
                                    <i className="fa-solid fa-plus" style={{ fontSize: 15, marginRight: 8 }} />
                                    {stepper4.content.details.btnSelect}
                                </button>
                        }
                    </>
                }
            >

                {
                    TipoInspeccionSelecteds.length !== 0 ? (
                        TipoInspeccionSelecteds.map((inp: EntitySelectTipoInspeccion) =>
                            <div key={inp.value} className='d-flex align-items-center border-bottom my-3 py-2' style={{ minHeight: 60 }}>
                                <i className='fa-regular fa-address-book' style={{ fontSize: 20, marginRight: 10 }} />
                                <div className='text-align-center'>
                                    <label className='text-primary'>{inp.label}</label>
                                    <p className='mb-0 text-muted'>{stepper4.content.textoCompruebaInformacion} {inp.label}</p>
                                </div>
                                {
                                    props.disabled ?
                                        null
                                    :
                                        <i className='fa-sharp fa-solid fa-circle-xmark' style={{ fontSize: 20, marginRight: 8, marginLeft: 'auto' }} onClick={() => props.onChange('TipoInspeccionSelect', inp)} />
                                }
                            </div>
                        )
                    ) : (
                        <ElementEmptyList text={stepper4.content.details.listEmpty} />
                    )
                }
            </ElementStepperContent>
        </>
    )
}



interface IViewDetalleItem {
    keyTab: number;
    onChangeContentForTab: (step: number) => any;
    onChange: (name: string, value: any) => void;
    onChangeItem: (name: string, value: any) => void;
    onChangeModalFormItem: (show?: boolean) => void;
    actionsFormItem: (typeAction: number, payload: any) => void;
    showModalFormItem: boolean;
    isModePreview?: boolean;
    isModeSurvey?: boolean;
    isBlockInputs: boolean;
    DetalleInspeccion: EntityDetalleInspeccion[];
    mode: { text: string; key: string; };
    formRegistro: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
    };
    formRegistroItem: {
        values: any;
        touched: any;
        errors: any;
        handleBlur: Function;
    }
    selectInformation: {
        AnomaliaInspeccion: EntitySelectAnomaliaInspeccion[];
        NivelGravedad: EntitySelectNivelGravedad[];
        Resultado: EntitySelectResultado[];
        Aplica: EntityAplica[];
        TipoSituacion: EntitySelectTipoSituacion[];
    }
}

export const ViewDetalleItems = (props: IViewDetalleItem) => {
    const [showModalList, setModalList] = useState(false);
    const [showModalFoto, setModalFoto] = useState({ show: false, type: '', deleteFromList: false });
    const [valueSelectTipoInspeccion, setValueSelectTipoInspeccion] = useState<any>(null);
    const { moduloInspeccion: { form: { stepper: { stepper4 } } } } = LanguageTranslate();
    const { formRegistro, formRegistroItem: { values }, selectInformation, showModalFormItem, onChangeModalFormItem } = props;

    const IdFormatoInspeccion = formRegistro.values.Formato?.dataComplete?.IdFormatoInspeccion || 0;
    // const IdTipoIspeccion = values.TipoInspeccion?.dataComplete.IdTipoInspeccion || 0;
    const IdResultado = values.Resultado?.value;

    const ListTipoInspeccion: EntitySelectTipoInspeccion[] = props.formRegistro.values?.TipoInspeccion || [];
    const ListTipoItem = selectInformation.AnomaliaInspeccion.filter(inp => inp.dataComplete.FormatoInspeccion.IdFormatoInspeccion === IdFormatoInspeccion);
    const ListItem = values.TipoItem?.dataComplete.Anomalia || [];
    // const ListDetalle = (values.Item?.dataComplete.TipoInspeccion?.find((item: any) => item.id_TipoInspeccion === IdTipoIspeccion))?.DetalleAnomalia || [];
    const ListPersonalSelected: EntitySelectPersonal[] = formRegistro.values["Personal"] || [];
    const { isBlockInputs, isModePreview, isModeSurvey } = props;

    return (
        <>
            {/** Modal Formulario */}
            <ElementModalCostume
                show={showModalFormItem}
                closeModal={() => onChangeModalFormItem(false)}
                title={props.isModePreview ? stepper4.content.details.titleModalView : (values.IdAnomalia ? stepper4.content.details.titleModalEdit : stepper4.content.details.titleModalCreate)}
            >
                <div className="inicioFormularioItem" />
                <InputSelect label={stepper4.content.items.modal.tipoInspeccion} name='TipoInspeccion' onChange={props.onChangeItem} values={values} options={ListTipoInspeccion} disabled={isBlockInputs} />

                <InputSelect label={stepper4.content.items.modal.resultado} name='Resultado' onChange={props.onChangeItem} values={values} isRequired options={selectInformation.Resultado} disabled={isBlockInputs} />


                <InputSelect label={stepper4.content.items.modal.tipoItem} name='TipoItem' onChange={props.onChangeItem} values={values} isRequired options={ListTipoItem} disabled={isBlockInputs} />
                <InputSelect label={stepper4.content.items.modal.item} name='Item' onChange={props.onChangeItem} values={values} isRequired options={ListItem} disabled={isBlockInputs} />
                {/*ListDetalle.length > 0 && <InputSelect label={stepper4.content.items.modal.detalle} name='Detalle' isRequired={values['Resultado']?.value === 1} onChange={props.onChangeItem} values={values} options={ListDetalle} disabled={isBlockInputs} />*/}

                {
                    IdResultado === 1 &&
                    <>
                        <InputSelect label={stepper4.content.items.modal.nivelGravedad} name='NivelGravedad' onChange={props.onChangeItem} values={values} isRequired options={selectInformation.NivelGravedad} disabled={isBlockInputs} />
                        {/* <InputCheck label={languageTranslate.moduloInspeccion.form.stepper.stepper2.content.paralizacionTrabajo} name='ParalizacionTrabajoItem' onChange={props.onChangeItem} values={values} disabled={isBlockInputs} /> */}
                    </>
                }

                <InputSelect label={stepper4.content.items.modal.personal} name='Personal' onChange={props.onChangeItem} values={values} isRequired isMulti closeMenuOnSelect controlShouldRenderValue options={ListPersonalSelected} disabled={isBlockInputs} />

                {
                    IdResultado === 1 &&
                    <>
                        <InputSelect label={stepper4.content.items.modal.tipoSituacion} name='TipoSituacion' onChange={props.onChangeItem} values={values} isRequired options={selectInformation.TipoSituacion} disabled={isBlockInputs} />
                        {/* <InputCheck label={stepper4.content.items.modal.grave} name='Grave' onChange={props.onChangeItem} values={values} disabled={isBlockInputs} /> */}
                        <InputText label={stepper4.content.items.modal.descripcion} name='Descripcion' onChange={props.onChangeItem} values={values} isRequired disabled={isBlockInputs} />
                        {!props.isModeSurvey && <InputCheck label={stepper4.content.items.modal.levantamientoInmediato} name='LevantamientoInmediato' onChange={props.onChangeItem} values={values} disabled={isBlockInputs} /> }
                        { values.LevantamientoInmediato ? null : <InputText label={stepper4.content.items.modal.accionPropuesta} name='AccionPropuesta' onChange={props.onChangeItem} values={values} isRequired disabled={isBlockInputs} /> }
                        {(props.mode.key === "create" && !!values.LevantamientoInmediato) ? null : <InputText label={stepper4.content.items.modal.fechaPrevista} type="date" name="FechaPrevista" onChange={props.onChangeItem} values={values} disabled={isBlockInputs} isRequired /> }
                        {
                            values.LevantamientoInmediato &&
                            <>
                                <InputText label={stepper4.content.items.modal.descripcionLevantamiento} name='DescripcionLevantamiento' onChange={props.onChangeItem} values={values} isRequired disabled={values.FotoLevantamiento?.some((row: any) => row.type === 'BD')} />
                                <InputText label={stepper4.content.items.modal.fechaLevantamiento} name='FechaLevantamiento' onChange={props.onChangeItem} values={values} isRequired disabled={values.FotoLevantamiento?.some((row: any) => row.type === 'BD')} type="date" />
                            </>
                        }
                    </>
                }

                {
                    IdResultado === 0 &&
                    <>
                        <InputText label={stepper4.content.items.modal.descripcion} name='Descripcion' onChange={props.onChangeItem} values={values} disabled={isBlockInputs} />
                    </>
                }
                <div className="d-flex justify-content-between col-11 mt-4">
                    <div style={{ minWidth: 100, textAlign: 'center' }}>
                        {(values.FotoInspeccion || !props.isModeSurvey) && <label className="text-danger mb-2">{stepper4.content.items.modal.imgTextoInspeccion}</label>}
                        <div className="d-flex justify-content-center gap-2">
                            {!isBlockInputs && <ElementInputFileImage icon="fa-solid fa-camera" capture multiple onChange={(file) => props.onChangeItem('FotoInspeccion', file)} size={20} />}
                            {!isBlockInputs && <ElementInputFileImage icon="fa-solid fa-image" multiple onChange={(file) => props.onChangeItem('FotoInspeccion', file)} size={20} />}
                            {values.FotoInspeccion.length > 0 && <i className="fa-solid fa-eye" style={{ fontSize: 20 }} onClick={() => setModalFoto({ show: true, type: 'FotoInspeccion', deleteFromList: false })} />}
                        </div>
                    </div>
                    {
                        values.LevantamientoInmediato &&
                        <div style={{ minWidth: 100, textAlign: 'center' }}>
                            <label className="text-danger mb-2">{stepper4.content.items.modal.imgTextoLevantamiento}</label>
                            <div className="d-flex justify-content-center gap-2">
                                {!values.FotoLevantamiento?.some((row: any) => row.type === 'BD') ?
                                <>
                                    <ElementInputFileImage icon="fa-solid fa-camera" capture multiple onChange={(file) => props.onChangeItem('FotoLevantamiento', file)} size={20} /> 
                                    <ElementInputFileImage icon="fa-solid fa-image" multiple onChange={(file) => props.onChangeItem('FotoLevantamiento', file)} size={20} /> 
                                </>
                                : null}
                                {values.FotoLevantamiento.length > 0 && <i className="fa-solid fa-eye" style={{ fontSize: 20 }} onClick={() => setModalFoto({ show: true, type: 'FotoLevantamiento', deleteFromList: false })} />}
                            </div>
                        </div>
                    }
                </div>

                {
                    <div className="d-flex justify-content-between mt-4 mb-3 flex-wrap">
                        {(!props.isBlockInputs || (props.isModeSurvey && IdResultado === 1 && !values.FotoLevantamiento?.some((row: any) => row.type === 'BD'))) && <button className="btn btn-primary w-100 mb-2" onClick={() => props.actionsFormItem(1, 1)}> {stepper4.content.items.modal.btnSave} </button>}
                        {!isBlockInputs && <button className="btn btn-primary w-100 mb-2" onClick={() => props.actionsFormItem(1, 2)}> {stepper4.content.items.modal.btnSaveAndContinue} </button>}
                        {(!props.isBlockInputs || (props.isModeSurvey && IdResultado === 1 && !values.FotoLevantamiento?.some((row: any) => row.type === 'BD'))) && <button className="btn btn-dark w-100" onClick={() => onChangeModalFormItem(false)}> {stepper4.content.items.modal.btnCancel} </button>}
                    </div>
                }
            </ElementModalCostume>

            {/** Modal Lista de AutoCompletados */}
            <ElementModalCostume
                show={showModalList}
                closeModal={() => { setModalList(false); setValueSelectTipoInspeccion(null); }}
                customHeader={
                    <div className="d-flex w-100">
                        <h6 className="mb-0"> {stepper4.content.items.title} </h6>
                        <InputSelectSimple
                            label=""
                            name="items"
                            onChange={(name: string, value: any) => setValueSelectTipoInspeccion(value)}
                            options={ListTipoInspeccion}
                            values={{ items: valueSelectTipoInspeccion ?? (ListTipoInspeccion[0] ?? null) }}
                            className='ms-3 w-50'
                        />
                    </div>
                }
            >
                <ItemsAutoComplete DetalleInspeccion={props.DetalleInspeccion} TipoInspeccion={valueSelectTipoInspeccion ?? (ListTipoInspeccion[0] ?? null)} ListTipoItem={ListTipoItem} selectInformation={selectInformation} actionsFormItem={props.actionsFormItem} />
            </ElementModalCostume>

            {/** Modal Fotos */}
            <ElementViewImagesCostume
                show={showModalFoto.show}
                closeModal={() => setModalFoto({ show: false, type: '', deleteFromList: false })}
                deleteImage={(indexImage: number) => props.onChangeItem('EliminarImage', { type: showModalFoto.type, indexImage, deleteFromList: showModalFoto.deleteFromList })}
                images={values[showModalFoto.type] || []}
                title={stepper4.content.items.modal.titleModalImage}
                disabled={(isModeSurvey && (showModalFoto.type === 'FotoInspeccion' || values.FotoLevantamiento?.some((row: any) => row.type === 'BD'))) || (isModePreview)}
            />

            {/* Lista de Detalle */}
            <ElementStepperContent
                isLastStep
                step={4}
                currentStep={props.keyTab}
                title={stepper4.content.items.title}
                onSubmit={props.onChangeContentForTab}
                removeButtonSubmit
                buttonRightCustom={() =>
                    <div className='d-flex'>
                        {
                            !isBlockInputs && (
                                <>
                                    <button onClick={() => setModalList(true)} className='btn btn-primary' style={{ marginRight: 8, height: 38, display: 'flex', alignItems: 'center' }}>
                                        <i className="fa-solid fa-list" style={{ fontSize: 15, marginRight: 8 }} />
                                        {stepper4.content.items.btnList}
                                    </button>
                                    <button onClick={() => onChangeModalFormItem()} className='btn btn-primary' style={{ height: 38, display: 'flex', alignItems: 'center' }}>
                                        <i className="fa-solid fa-plus" style={{ fontSize: 15, marginRight: 8 }} />
                                        {stepper4.content.items.btnAdd}
                                    </button>
                                </>
                            )
                        }
                    </div>
                }
            >
                {props.DetalleInspeccion.map((isp, index) => <ItemDetalleInspeccion id={index + 1} data={isp} actionsFormItem={props.actionsFormItem} key={isp.IdAnomalia} setModalFoto={setModalFoto} onChangeItem={props.onChangeItem} isBlockInputs={isBlockInputs} isModePreview={props.isModePreview} />)}
                {props.DetalleInspeccion.length === 0 && <ElementEmptyList text={stepper4.content.items.listEmpty} />}

            </ElementStepperContent>
        </>
    )
}



interface IItemAutoComplete {
    ListTipoItem: EntitySelectAnomaliaInspeccion[];
    selectInformation: {
        AnomaliaInspeccion: EntitySelectAnomaliaInspeccion[];
        Resultado: EntitySelectResultado[];
        Aplica: EntityAplica[];
    };
    actionsFormItem(type: 4, payload: EntityAutoCompleteItem): void;
    TipoInspeccion: any | null;
    DetalleInspeccion: EntityDetalleInspeccion[];
}

const ItemsAutoComplete = (props: IItemAutoComplete) => {
    const { ListTipoItem, actionsFormItem, selectInformation, TipoInspeccion } = props;
    const { moduloInspeccion: { form: { stepper: { stepper4 } } } } = LanguageTranslate();
    let contador = 0;

    return (
        <>
            {
                AdapterGenerico.sortListOption(ListTipoItem).map(tip => {
                    // const cantAnomalias = tip.dataComplete.Anomalia.length;
                    // const Aplica = selectInformation.Aplica.find(apl => apl.Codigo === tip.dataComplete.Codigo);
                    // const listCodes = Aplica?.Anomalia.map(temp => temp.Codigo) || [];
                    // const selectAll = (Aplica?.Anomalia.length || 0) === cantAnomalias;

                    const anomalias = tip.dataComplete.Anomalia.filter((item: any) => {
                        return !props.DetalleInspeccion.find(row => item.dataComplete.Codigo === row.Anomalia.Codigo && row.TipoInspeccion.IdTipoInspeccion === props.TipoInspeccion?.dataComplete?.IdTipoInspeccion);
                        // let exists: boolean = listCodes.includes(item.dataComplete.Codigo);
                        // if (!exists) {
                        //     return !exists;
                        // }

                        // let tipoInspeccion: any = item.dataComplete.TipoInspeccion.find((subitem: any) => subitem.id_TipoInspeccion == TipoInspeccion?.dataComplete.IdTipoInspeccion)
                        // return tipoInspeccion.DetalleAnomalia.length ? true : false;
                    });

                    // const anomalias = tip.dataComplete.Anomalia;

                    // if (selectAll || cantAnomalias === 0) {
                    if (anomalias.length === 0) {
                        contador++;
                        return contador === ListTipoItem.length ? (<ElementEmptyList key={tip.value} text={stepper4.content.details.listEmpty} className="top-50 start-50 translate-middle position-absolute" />) : <div key={tip.value}></div>;
                    };

                    return (
                        <>
                            <label className="mt-3">{tip.label}</label>
                            {
                                AdapterGenerico.sortListOption(anomalias as any[]).map((aml: any, index) =>
                                    <div key={index} className='d-flex align-items-center border-bottom mb-3 py-2' style={{ minHeight: 60 }}>
                                        <i className='fa-regular fa-address-book' style={{ fontSize: 20, marginRight: 10 }} />
                                        <div className='text-align-center px-2'>
                                            <label className='text-primary'>{aml.value}. {aml.label}</label>
                                        </div>
                                        <div className="d-flex" style={{ width: 65, marginLeft: 'auto' }}>
                                            <button className="btn btn-success rounded-circle d-flex justify-content-center" style={{ maxWidth: 30, marginRight: 5 }} onClick={() => actionsFormItem(4, { TipoItem: tip, Item: aml, Resultado: { value: 0, label: "Correcto" }, TipoInspeccion: TipoInspeccion })}>
                                                <i className="fa-solid fa-thumbs-up" style={{ fontSize: 16, color: 'white' }} />
                                            </button>
                                            <button className="btn btn-danger rounded-circle d-flex justify-content-center" style={{ maxWidth: 30 }} onClick={() => actionsFormItem(4, { TipoItem: tip, Item: aml, Resultado: { value: 1, label: "No Conformidad" }, TipoInspeccion: TipoInspeccion })}>
                                                <i className="fa-solid fa-thumbs-down" style={{ fontSize: 16, color: 'white' }} />
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )
                })
            }
        </>
    )
}



interface IItemDetalleInspeccion {
    data: EntityDetalleInspeccion;
    actionsFormItem: (typeAction: number, payload: any) => void;
    onChangeItem: (name: string, value: any) => void;
    setModalFoto: React.Dispatch<React.SetStateAction<{ show: boolean; type: string; deleteFromList: boolean; }>>
    id: number;
    isBlockInputs?: boolean;
    isModePreview?: boolean;
}

const ItemDetalleInspeccion = ({ data, actionsFormItem, id, setModalFoto, onChangeItem, isBlockInputs, isModePreview }: IItemDetalleInspeccion) => {
    const [showMore, setShowMore] = useState(false);
    const Critica = [1].includes(data.Anomalia.ResultadoAnomalia);
    const isGrave = ['si'].includes((data.Anomalia.Critica || '').toLowerCase());
    const { moduloInspeccion: { form: { stepper: { stepper4 } } }, code } = LanguageTranslate();
    const openModal = () => {
        onChangeItem('PreviewListImageInspeccion', data.Anomalia.Fotos);
        onChangeItem('IdAnomalia', data.IdAnomalia);
        setModalFoto({ show: true, type: 'FotoInspeccion', deleteFromList: true });
    }

    return (
        <div className="container-levantamiento shadow rounded mb-3">
            <div className={`w-100 p-3 ${showMore ? 'rounded-top' : 'rounded'} position-relative header d-flex justify-content-between`} style={{ background: data.Incompleta ? '#b95252' : data.Sancionado === 'SI' ? '#A19153' : '#2b3139' }}>
                <label className="text-truncate" style={{ width: 'max-content' }}> {id}.- {data.Anomalia?.Idioma?.find(row => row.codigo === code)?.nombre || data.Anomalia.Anomalia}</label>
                <div className="d-flex">
                    <i className={`fa-solid ${showMore ? 'fa-chevron-down' : 'fa-chevron-up'} mx-2`} style={{ fontSize: 18 }} onClick={() => setShowMore(!showMore)} />
                    <i className={`fa-solid ${(isModePreview || !!data.Levantamiento.Fecha.Fecha || (isBlockInputs && data.Anomalia.ResultadoAnomalia === 0)) ? 'fa-circle-info' : 'fa-pen-to-square mx-2'}`} style={{ fontSize: 18 }} onClick={() => actionsFormItem(2, data.IdAnomalia)} />
                    {!isBlockInputs && <i className="fa-solid fa-trash mx-2" style={{ fontSize: 18 }} onClick={() => actionsFormItem(3, data.IdAnomalia)} />}
                </div>
            </div>
            {
                showMore && (
                    <div className="w-100 p-3 rounded-bottom content row position-relative">
                        <label className={`${data.Incompleta ? 'text-danger' : (Critica ? "text-danger" : "text-success")} position-absolute`} style={{ width: "auto", right: -10 }} >
                            {data.Incompleta ? stepper4.content.items.item.textIncompleta : (Critica ? (isGrave ? stepper4.content.items.item.textGrave : stepper4.content.items.item.textNoConforme) : stepper4.content.items.item.textCorrecto)}
                        </label>
                        <div className="col-11">
                            <div className="d-flex">
                                <span>{stepper4.content.items.item.tipoInspeccion}:</span>
                                <span className="d-inline-block text-uppercase text-truncate text-muted">
                                    &nbsp;{data.TipoInspeccion.Idioma?.find(row => row.codigo === code)?.nombre ||  data.TipoInspeccion.TipoInspeccion}
                                </span>
                            </div>
                            <div className="d-flex">
                                <span>{stepper4.content.items.item.tipoItem}:</span>
                                <span className=" text-muted">
                                    &nbsp;{data.Anomalia.TipoAnomalia?.Idioma?.find(row => row.codigo === code)?.nombre || data.Anomalia.TipoAnomalia.TipoAnomalia}
                                </span>
                            </div>
                            <div className="d-flex">
                                <span>{stepper4.content.items.item.item}:</span>
                                <span className="text-muted">
                                    &nbsp;{data.Anomalia?.Idioma?.find(row => row.codigo === code)?.nombre || data.Anomalia.Anomalia}
                                </span>
                            </div>
                            <div className="d-flex f-wrap">
                                <span>{stepper4.content.items.item.descripcion}:</span>
                                <span className="text-muted">&nbsp;{data.Descripcion}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between col-11 mt-4">
                            <div>
                                <label className="text-danger mb-2">{stepper4.content.items.item.textImgInspeccion}</label>
                                <div className="d-flex justify-content-center gap-2">
                                    {
                                    !isBlockInputs &&
                                        <>
                                            <ElementInputFileImage icon="fa-solid fa-camera" capture multiple onChange={(file) => actionsFormItem(5, { file, index: (id - 1) })} size={20} />
                                            <ElementInputFileImage icon="fa-solid fa-image" multiple onChange={(file) => actionsFormItem(5, { file, index: (id - 1) })} size={20} />
                                        </>
                                    }
                                    {data.Anomalia.Fotos.length > 0 && <i className="fa-solid fa-eye" style={{ fontSize: 20, marginLeft: 10 }} onClick={openModal} />}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};