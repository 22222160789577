const SCHEMA: string = 'Maestro';
const ENTITY: string = 'Pais';
const SAVE_RDI: string = '/ProcesoRDI'
const SAVE_RDI_LEVANTAMIENTO: string = '/observacionSolucion/ReporteIncidente2';
const REDIRECT_PATH_CANCEL: string = '/main/RDI';
const ID_USER_INVITE = 0;
const MODE_BLOCK_INPUTS = ['view', 'viewGuest', 'levantamiento'];
const MODE_BLOCK_INPUTS_PROPOSED_MEASURES = ['view', 'viewGuest'];
const MODE_REQUIRED_FILES_BY_TIPO_INCIDENCIA = ['C/S', 'C/A'];
const KEY_ALL_ADMIN = 999;

export const AdapterConfigure = {
    REDIRECT_PATH_CANCEL,
    SAVE_RDI,
    SAVE_RDI_LEVANTAMIENTO,
    SCHEMA,
    ENTITY,
    ID_USER_INVITE,
    MODE_BLOCK_INPUTS,
    MODE_BLOCK_INPUTS_PROPOSED_MEASURES,
    MODE_REQUIRED_FILES_BY_TIPO_INCIDENCIA,
    KEY_ALL_ADMIN
};