import { EntityLanguage } from "../context/shared/Domain/EntityLanguage";

/* NOTA: modificar solo el valor, no la llave */
export const idioma_pt: EntityLanguage = {
    code: 'pt',
    nombreProyecto: 'Sistema de Gestão de Segurança Operacional',
    textoLogin: 'Iniciar Sessão',
    textoConfiguracion: 'Configuração',
    textoCatalogos: 'Atualizar dados',
    textoCerrarSesion: 'Encerrar Sessão',
    textoModoCrear: 'CRIAR',
    textoModoEditar: 'EDITAR',
    textoModoVisualizar: 'VISUALIZAR',
    textoModoLevantamiento: 'REVOLTA',
    textoNavbarInicio: 'Início',
    textoConfigurandoAreaTrabajo: 'Configurando seu ambiente de trabalho...',
    textoSincronizacionSemanalCatalogo: "Sincronização semanal de catálogo",
    textoNuevaVersion: 'Nova versão, clique aqui para atualizar',
    textoGuardando: 'Salvando...',
    textoCargando: 'Carregando...',
    textoAlerta: 'Alerta',
    textoIncompleto: 'Incompleto',
    textoExitoso: 'Bem-sucedido',
    textoSinConexion: 'Sem Conexão',
    textoDescripcionSinConexion: 'Sem conexão com a internet',
    textoValidacionTerminos: 'Você deve aceitar os termos',
    textoCambioPais: 'Mudou de país para',
    textoPagina: 'Página',
    textoPaginaDe: 'de',
    textoNro: 'Nro.',
    btnCancelar: 'Cancelar',
    btnGuardar: 'Salvar',
    btnSiguiente: 'Próximo',
    btnVerImagen: 'Ver imagens',
    btnRegresar: 'Voltar',
    btnAgregar: 'Adicionar',
    textoCargandoAudio: 'O processamento do áudio, dependendo da duração, pode demorar mais...',
    btnDetener: 'Parar',
    btnReproducir: 'Reproduzir',
    btnLimpiar: 'Limpar',
    btnGuardarFirma: 'Salvar Assinatura',
    btnVerFirma: 'Ver Assinatura',
    btnSi: 'Sim',
    btnNo: 'Não',
    btnBuscar: 'Achar',
    btnSeleccionar: 'Selecionar',
    btnRecursoPreventivo: 'Recurso Preventivo',
    btnDescargar: 'Baixar',
    btnDescargando: 'Transferindo...',
    moduloConfiguracion: {
        textoPreferencia: "Preferência",
        textoPais: "País",
        textoDelegacion: "Delegação",
        textoEmpresa: 'Companhia',
        textoOT: "Ordem de Trabalho",
        textoIdioma: "Idioma",
        textoCambioPassword: "Alterar Senha",
        textoPassword: "Senha",
        textoNuevaPassword: "Nova Senha",
        textoRepetirNuevaPassword: "Repetir Nova Senha",
        textoBotonSaveCambioPassword: "Salvar",
        textoPersonalMediosHumanos: "Pessoal de Recursos Humanos",
        textoBuscarCuadrillaPersonal: "Buscar Equipe de Recursos Humanos",
        textoAutomatizaPersonal: "Automatizar Pessoal de Recursos Humanos",
        validate: {
            formPreferencia: {
                pais: "Selecione um País",
                delegacion: "Selecione uma Delegação",
                ot: "Selecione uma Ordem de Trabalho",
                personal: "Selecione um pessoal de Recursos Humanos"
            },
            formChangePassword: {
                password: "Digite a Senha",
                newPassword: "Digite a Nova Senha",
                reNewPassword: "Digite Repetir Nova Senha",
                formatNewPassword: 'Sua nova senha deve ter pelo menos 12 caracteres e incluir pelo menos uma letra maiúscula, um número e um caractere especial'
            },
            cambioPasswordCorrecto: "A alteração de senha foi realizada com sucesso"
        }
    },
    moduloLogin: {
        textoTitulo: "Login",
        txtUsuario: "Usuário",
        txtPassword: "Senha",
        btnLogin: "LOGIN",
        chkRecordarUsuario: "Lembrar-me",
        textoOlvidoContrasenia: "Esqueceu sua senha?",
        textoRegresarMain: "Voltar para a página principal",
        textoRegistrarRDI: "Faça login como convidado",
        textoTitleSelectPais: "Selecionar País",
        textoPlaceholderSelectPais: "País",
        textoErrorSelectPais: "Você deve selecionar uma opção",
        validate: {
            formLogin: {
                username: "Digite o Usuário",
                usernameMinimo: "Mínimo de 3 caracteres",
                password: "Digite a Senha",
                passwordMinimo: "Mínimo de 3 caracteres"
            }
        }
    },
    moduloRecuperarPassword: {
        textoTitulo: "Recuperar Senha",
        textoUsuario: "Usuário",
        textoIdentificacion: "Número de Identificação",
        textoCorreoElectronico: "E-mail",
        textoAccion: "Recuperar",
        textoRegresarLogin: "Voltar ao Login",
        validate: {
            form: {
                    username: "Digite o Usuário",
                    usernameMinimo: "Mínimo de 3 caracteres",
                    identificacion: "Digite o Número de Identificação",
                    identificacionFormato: "O Número de Identificação está incorreto",
                    correoElectronico: "Digite o E-mail",
                    correoElectronicoFormato: "O E-mail está incorreto"
            },
            recuperarPasswordCorrecto: "A recuperação de senha foi realizada com sucesso. Você receberá uma mensagem no e-mail registrado."
        }
    },
    modulo404: {
        textoDescripcion: "O recurso solicitado não está disponível",
        textoHome: "Voltar para o Início"
    },
    moduloNotAvailable: {
        textoCabecera: "Recurso Não Disponível",
        textoDescripcion: "O recurso solicitado não está disponível para o seu dispositivo"
    },
    moduloMain: {
        textoTitulo: "Sistema de Gestão de Segurança Operacional",
        textoBienvenida: "Seja bem-vindo!",
        textoInicio: "Início",
        textoAyuda: "Ajuda",
        textoTituloPreguntaAyuda: "Redirecionamento",
        textoDescripcionPreguntaAyuda: "Você será redirecionado para a documentação de ajuda do sistema. Tem certeza?",
        textoReporte: "Reportar",
        textoRefrescarApp: `Atualizar aplicativo`,
        textoNotificacion: 'Notificação',
        textoTituloPreguntaReporte: "Redirecionamento",
        textoDescripcionPreguntaReporte: "Você será redirecionado para o Sistema de Gerenciamento de Serviços. Tem certeza?"
    },
    moduloCatalogo: {
        textoRefrescar: "Atualizar",
        textoRefrescarTodo: "Atualizar Tudo",
        textoSincronizado: "Estado: Sincronizado",
        textoNoSincronizado: "Estado: Não Sincronizado"
    },
    moduloRDI: {
        list: {
          navbar: [{ text: "RDI", navigate: true, path: "/main/RDI" }, { text: "Lista", navigate: false, path: "" }],
          btnAgregar: "ADICIONAR",
          textoVacio: "Sem Informações",
          textoEstado: "Estado",
          validate: {
            formFilter: {
              fechaInicio: "Digite a Data de Início",
              fechaFin: "Digite a Data de Fim"
            }
          }
        },
        form: {
          navbar: [{ text: "RDI", navigate: true, path: "/main/RDI" }, { text: "Lista", navigate: false, path: "" }],
          textoPais: "País",
          textoDelegacion: "Delegação",
          textoEmpresa: "Empresa",
          textoSubcontrata: "Sub Contrata",
          textoCliente: "Cliente",
          textoActividad: "Atividade",
          textoUsuarioRegistra: "Usuário Registrante",
          textoFechaHoraIncidencia: "Data e Hora da Incidência",
          textoTipoIncidencia: "Tipo de Incidência",
          textoDescripcionIncidencia: "Descrição da Incidência",
          textoMedidasPropuestas: "Solução proposta",
          textoDescripcionSolucion: 'Descrição da solução',
          textoFotoSubsanacion: 'Correção de fotos',
          textoUsuarioMedidasPropuestas: "Gerenciador de Soluções",
          textoObra: "Obra",
          textoProvincia: "Província",
          textoDireccion: "Endereço",
          textoFactorRiesgo: "Fator de Risco",
          textoTipoIncidente: "Próprio / Subcontrato",
          textoParalizacionTrabajo: "Paralisação do Trabalho",
          textoCondicionInsegura: "Condição Insegura",
          textoVehiculoAfectado: "Veículo Afetado",
          textoVerImagenes: "Ver imagem",
          textoPlaca: "Placa do Veículo Afetado",
          textMensajeAgradecimiento: "A RDI foi registrada com sucesso e já foi comunicada às demais pessoas envolvidas. Muito obrigado por relatar este incidente. Com seu relatório, você está ajudando a melhorar sua segurança e a de seus colegas.",
          textoFotosIncidencia: "Fotos do incidente",
          textoAyudaSolucion: 'Preencha apenas se o problema estiver resolvido',
          modal: {
            textoTitulo: "Imagem",
            btnEliminarImagen: "EXCLUIR IMAGEM",
            textoSinImagenes: "Sem imagens"
          },
          btnNext: "Próximo",
          btnSave: "Salvar",
          stepper: {
            stepper1: {
              text: "Informações Gerais",
              content: {
                title: "INFORMAÇÕES GERAIS"
              }
            },
            stepper2: {
              text: "Detalhes da Incidência",
              textDetalleSolucion: "Medidas propostas",
              textSolucion: 'Solução',
              content: {
                title: "DETALHES DA INCIDÊNCIA"
              }
            }
          },
          validate: {
            formRegistro: {
              ot: "Selecione uma Ordem de Trabalho",
              delegacion: "Selecione uma Delegação",
              empresa: "Selecione uma Empresa",
              cliente: "Selecione um Cliente",
              actividad: "Selecione uma Atividade",
              usuarioRegistro: "Selecione um Usuário Registrante",
              fechaIncidente: "Selecione uma Data e Hora da Incidência",
              tipoIncidencia: "Selecione um Tipo de Incidência",
              breveDescripcionIncidente: "Insira uma Descrição da Incidência",
              factorRiesgo: "Selecione pelo menos um Fator de Risco",
              tipoIncidente: "Selecione pelo menos um Tipo de Incidente",
              condicionInsegura: "Selecione uma Condição Insegura",
              vehiculoAfectado: "Selecione o Veículo Afetado",
              cantidadImagenes: "A quantidade máxima de imagens permitida é 10",

              propuestaSolucion: 'Insira uma proposta de solução',
              descripcionSolucion: 'Insira uma descrição da solução',
              responsableSolucion: 'Insira um responsável pela solução',
              fotoSolucion: 'Insira uma foto de reparo',

              fotoIncidencia: 'Envie uma imagem do problema',
              fotoSolucionRequerimientroPrevioFotoIncidencia: 'Primeiro, deve enviar uma foto do problema'
            }
          },
          TipoIncidentePersonal: "Pessoal Próprio",
          TipoIncidenteSubContrata: "Pessoal Subcontratado"
        },
    },
    moduloInspeccion: {
        list: {
          navbar: [{ text: "Inspeções", navigate: false, path: "" }],
          navbarOPS: [{ text: "OPS", navigate: false, path: "" }],
          btnAgregar: "ADICIONAR",
          textoVacio: "Sem Inspeções",
          textoVacioOPS: "Sem Inspeções OPS",
          textoEnviandoCorreo: "Enviando e-mail...",
          modalEmail: {
            title: "Enviar E-mail",
            correoElectronico: "E-mail",
            tableEmail: "E-MAILS",
            cc: "CC",
            btnEnviar: "Enviar"
          },
          modalSearch: {
            titulo: "Pesquisa Personalizada",
            fechaInicial: "Data Inicial",
            fechaFinal: "Data Final",
            tipoOperacion: "Tipo de Operação",
            identificacion: "Identificação",
            buscarInspeccionesFinalizadas: "Buscar inspeções finalizadas",
            codigoInspeccion: "Código da Inspeção",
            btnBuscar: "Buscar"
          },
          item: {
            jefeObra: "Chefe da Obra",
            autor: 'Autor',
            nroNoConformidad: 'Nº. Não conformidade',
            fechaInspeccion: "Data da Inspeção",
            empresa: "Empresa",
            subContrata: "Sub Contrata",
            tipoOperacion: "Tipo de Operação",
            personal: "Pessoal"
          },
          validate: {
            correoExiste: "E-mail já existe",
            correoEnviado: "Enviado com sucesso",
            fechaInicio: "Digite a Data de Início",
            fechaFinal: "Digite a Data de Fim",
            fechaRangoInicioFinal: "A data inicial não deve ser maior que a final",
            tipoOperacion: "Digite pelo menos um tipo de operação",
            identificacion: "Digite a identificação"
          }
        },
        form: {
          navbar: [{ text: "Inspeções", navigate: true, path: "/main/Inspeccion" }],
          navbarOPS: [{ text: "OPS", navigate: true, path: "/main/OPS" }],
          navbarText: {
            modeCrear: "Criar",
            modeVisualizar: "Visualizar",
            modeLevantamiento: "Levantamento"
          },
          btnNext: "Próximo",
          btnSave: "Salvar",
          stepper: {
            stepper1: {
              text: "Informações Gerais",
              content: {
                title: "INFORMAÇÕES GERAIS",
                ot: "OT",
                area: "Área / Filial",
                empresa: "Empresa",
                actividad: "Atividade",
                cliente: "Cliente",
                jefeObra: "Chefe da Obra",
                coordinador: "Técnico de Segurança",
                encargado: "Encarregado"
              }
            },
            stepper2: {
              text: "Informações da Inspeção",
              content: {
                title: "INFORMAÇÕES DA INSPEÇÃO",
                responsableLevantamiento: "Responsável pelo Levantamento",
                inspeccionConjunta: "Inspeção Conjunta",
                personalConjunta: "Pessoal em Conjunto",
                inspeccionSubcontrataConjunta: "Inspeção Subcontratada Conjunta",
                subContrata: "Sub Contrata",
                tipoOperacion: "Tipo de Operação",
                formato: "Formato",
                paralizacionTrabajo: "Paralisação do Trabalho",
                momento: "Momento",
                lugar: "Lugar",
                obraTD: "Obra / TD",
                trabajoRealizar: "Trabalho a ser Realizado",
                placa: "Placa / Placa",
                reconocimientoSST: 'Reconhecimento do bom desempenho em matéria de SST',
                observacionOPS: 'Observação da OPAS',
                fueraHorario: "Após o expediente",
              }
            },
            stepper3: {
              text: "Informações Pessoais",
              content: {
                title: "INFORMAÇÕES PESSOAIS",
                personal: "Pessoal",
                table: {
                  id: "ID",
                  name: "Nome",
                  lastName: "Sobrenome",
                  responsable: "Responsável pelo Trabalho",
                  recursoPreventivo: 'Recurso Preventivo'
                }
              }
            },
            stepper4: {
              text: "Informações do Registro",
              content: {
                details: {
                  title: "TIPO DE INSPEÇÃO",
                  btnSelect: "Selecionar",
                  listEmpty: "Nenhum tipo de inspeção selecionado",
                  titleModal: "Selecione o Tipo de Inspeção",
                  titleModalView: 'Informações do item',
                  titleModalCreate: 'Criar item',
                  titleModalEdit: 'Editar item'
                },
                items: {
                  title: "LISTA DE ITENS",
                  btnList: "Lista",
                  btnAdd: "Adicionar",
                  listEmpty: "Sem registros",
                  modal: {
                    tipoInspeccion: "Tipo de Inspeção",
                    tipoItem: "Tipo de Item",
                    item: "Item",
                    detalle: "Detalhe",
                    personal: "Pessoal",
                    resultado: "Resultado",
                    nivelGravedad: "Nível de Gravidade",
                    tipoSituacion: "Tipo de Situação",
                    grave: "Grave",
                    descripcion: "Descrição",
                    accionPropuesta: "Ação Proposta",
                    levantamientoInmediato: "Levantamento Imediato",
                    descripcionLevantamiento: "Descrição do Levantamento",
                    fechaLevantamiento: 'Pesquisa de Data',
                    imgTextoInspeccion: "Inspeção",
                    imgTextoLevantamiento: "Levantamento",
                    titleModalImage: "Visualizar Imagens",
                    fechaPrevista: 'Data prevista',
                    btnSave: "Salvar",
                    btnSaveAndContinue: "Salvar e Continuar",
                    btnCancel: "Cancelar"
                  },
                  item: {
                    tipoInspeccion: "Tipo de Inspeção",
                    tipoItem: "Tipo de Item",
                    item: "Item",
                    descripcion: "Descrição",
                    textIncompleta: "Incompleto",
                    textCorrecto: "Correto",
                    textGrave: "Grave",
                    textNoConforme: "Não Conforme",
                    textImgInspeccion: "Inspeção"
                  }
                },
                textoCompruebaInformacion: 'Verifique suas informações em',
                textObservacionGeneral: "Observação Geral"
              }
            }
          },
          validate: {
            formRegistro: {
              ot: "Selecione uma OT",
              area: "Selecione uma Área",
              empresa: "Selecione uma Empresa",
              actividad: "Selecione uma Atividade",
              cliente: "Selecione um Cliente",
              jefeObra: "Selecione um Chefe da Obra",
              coordinador: "Selecione um Coordenador",
              conjuntaPersonal: "Selecione um Pessoal em Conjunto",
              tipoOperacion: "Selecione um Tipo de Operação",
              formato: "Selecione um Formato",
              tipoMomentoTrabajo: "Selecione um Tipo de Momento",
              lugar: "Digite um Local",
              obra: "Digite uma Obra",
              trabajoRealizar: "Digite um Trabalho a ser Realizado",
              observacionOPS: "Digite uma observação OPS",
              personalResponsableTrabajo: "Selecione um Responsável pelo Trabalho",
              detalleInspeccionVacia: "Digite pelo menos um item",
              detalleInspeccionIncompleta: "Complete todos os itens",
              responsableLevantamiento: "Selecione um Responsável pelo Levantamento",
              cantidadImagenes: (quantidadeMaxima: any) => `A quantidade máxima de imagens permitida é ${quantidadeMaxima}.`
            },
            formRegistroItem: {
              tipoInspeccion: "Selecione o Tipo de Inspeção",
              tipoItem: "Selecione o Tipo de Item",
              item: "Selecione o Item",
              detalle: "Selecione o Detalhe",
              personal: "Selecione um Pessoal",
              resultado: "Selecione um Resultado",
              nivelGravedad: "Selecione um Nível de Gravidade",
              descripcion: "Digite uma Descrição",
              accionPropuesta: "Digite uma Ação Proposta",
              tipoSituacion: "Selecione um Tipo de Situação",
              descripcionLevantamiento: "Digite uma Descrição do Levantamento",
              fechaLevantamiento: 'Insira uma data de elevação',
              fotoLevantamiento: "Digite uma Imagem do Levantamento",
              cantidadImagenes: "Todas as imagens são necessárias? (verifique se nenhuma imagem está sendo repetida)",
              fechaPrevista: 'Insira uma data esperada'
            },
            noItem: "Item não encontrado",
            noDetalleItem: "Detalhe do item não encontrado",
            detalleItemDuplicado: "Detalhe do item duplicado",
            detalleItemNoPermitido: "Não é permitido adicionar o item",
            noTipoInspeccion: "Você deve selecionar um Tipo de Inspeção para preenchimento automático"
          },
          TipoMomento: {
            inicio: 'Início do trabalho',
            durante: 'Durante o trabalho',
            fin: 'Fim do trabalho'
          },
          textoBuscarCuadrillaPersonal: "Buscando equipe de pessoal...",
          textoCorrecto: "Correto",
          textoNoConforme: "Não conformidade",
          nivelSeguridad: {
            textoAlta: "Alta",
            textoMedia: "Média",
            textoLeve: "Leve",
          },
          tipoSituacion: {
              textoActoInseguro: "Ato inseguro",
              textoCondicionInsegura: "Condição Insegura"
          }
        }
    },
    moduloAutoChequeo: {
        list: {
          TituloListaAutochequeo: "LISTA AUTOCHEQUEO",
          TituloListaControlPreventivo: "CONTROLE PREVENTIVO",
          navbar: [{ text: "Gestão de Autoverificação", navigate: false, path: "" }],
          navbarGuest: [{ text: 'Conversa pré-tarefa', navigate: false, path: '' }],
          Estado: "Estado",
          textoCPPendiente: 'CP pendente',
          EstadoItems: {
            EstadoActivo: "Ativo",
            EstadoInactivo: "Inativo"
          },
          Resultado: "Resultado",
          ResultadoItems: {
            ResultadoCorrecto: "Correto",
            ResultadoIncorrecto: "Incorreto"
          },
          Autor: "Autor",
          FechaRegistro: "Data de Registro",
          textAudio: 'Áudio',
          textPDF: 'PDF',
          search: {
            Titulo: "Pesquisar Autoverificação",
            FechaInicial: "Data Inicial",
            FechaFinal: "Data Final",
            Identificacion: "Identificação",
            BtnBuscar: "Pesquisar",
            BusquedaGrupal: "Pesquisar registros ativos",
            BuscarRegistrosActivos: ""
          },
          validate: {
            formFilter: {
              fechaInicial: "Selecione uma Data Inicial",
              fechaFinal: "Selecione uma Data Final",
              identificacion: "Digite uma Identificação"
            }
          }
        },
        form: {
          navBarTextCharlaPretarea: "Conversa Preliminar",
          navBarTextListaAutochequeo: "Lista de Autoverificação",
          navBarTextControlPreventivo: "Controle Preventivo",
          navBarTextCierreControlPreventivo: "Encerramento do Controle Preventivo",
          navBarTextPreview: "Visualizar",
          navBarGuest: [{ text: 'Conversa pré-tarefa', navigate: true, path: '/main/GestionAutochequeo' }, { text: 'Detalhe', navigate: false, path: '' }],
          tituloDatosGenerales: "Dados Gerais",
          tituloDatosControlPreventivo: "Dados de Controle Preventivo",
          textoAyudaControlPreventivo: "Contém encerramento de controle preventivo",
          textoAyudaNecesitaRegistrarLAutochequeo: "Esta OT precisa que a lista de autoverificação seja feita primeiro. Se já foi feito, ignore esta mensagem",
          btnAutcompletarUltimoRegistro: "Preenchimento automático a partir do último registro",
          inputs: {
            ot: "OT",
            empresa: "Empresa",
            subContrata: "SubContrata",
            delegacion: "Delegação",
            actividad: "Atividade",
            recursoPreventivo: "Recurso Preventivo",
            cliente: "Cliente",
            obra: "Obra",
            lugar: "Local",
            trabajoRealizar: "Trabalho a ser realizado",
            jefeObra: "Chefe da Obra",
            tituloGrupo: "Informações por grupo",
            ordenServicio: "Ordem de Serviço / Ordem de Serviço",
            txtObservacion: "Observação",
            txtPersonal: "Pessoal",
            textFirmaJefeTrabajo: 'Assinatura do Chefe de Trabalho',
            textBien: 'Bom',
            textMal: 'Mau'
          },
          preguntarRealizarCierreControlPreventivo: "Deseja encerrar o Controle Preventivo?",
          advertenciaListaAutochequeoRealizado: "A lista de autoverificação já foi feita",
          advertenciaSinRegistro: "Registro não encontrado",
          advertenciaRegistroConCierre: "Este registro já possui um Encerramento",
          advertenciaCargarOT: "Ocorreu um erro ao carregar as OTs",
          advertenciaBuscarOTRegistro: "Ordem de Serviço vinculada ao registro não encontrada",
          advertenciaRegistroSinCierre: "Esta OT não possui um Encerramento de Controle Preventivo",
          advertenciaOTSinTipologias: "Esta OT não possui tipologias",
          advertenciaOTSinPreguntas: "Esta OT não possui perguntas",
          advertenciaOTSinPreguntasAutochequeo: "Esta OT não possui perguntas para mostrar, tente outra",
          advertenciaOTPreview: (text: string) => `Não foi possível encontrar o OT em que você se registrou ${text}, passe por todo o processo novamente`,
          textTipologia: "Tipologias",
          tipologiaVacio: "Sem Tipologias",
          textCargandoPreguntas: "Carregando Perguntas",
          textCargandoTipologias: "Carregando Tipologias",
          textRegistroIncompleto: "Tem um registro não completado. Deseja carregá-lo?",
          validate: {
            formRegistro: {
              ordenServicio: "Digite uma Ordem de Serviço / Ordem de Serviço",
              ot: "Selecione uma OT",
              otDirectoCP: "Você precisa completar primeiro a 'Lista de Autoverificação' ou a 'Conversa Preliminar'",
              tipologia: "Selecione pelo menos uma tipologia",
              empresa: "Selecione uma Empresa",
              delegacion: "Selecione uma Delegação",
              actividad: "Selecione uma Atividade",
              cliente: "Selecione um Cliente",
              obra: "Digite uma Obra",
              lugar: "Digite um Local",
              trabajoRealizar: "Digite o Trabalho a ser realizado",
              jefeObra: "Selecione um Chefe da Obra",
              usuarioRegistra: 'Selecionar registros de usuário'
            },
            formDinamico: {
              preguntaNoRespondido: "Não respondeu à pergunta número",
              preguntaNoEstadoEquipo: "Selecione o 'estado do equipamento' na pergunta número",
              preguntaNoObservacion: "Digite uma 'observação' na pergunta número",
              preguntaNoAudio: "Registre um 'áudio' na pergunta número",
              preguntaNoPersonal: "Digite 'pessoal' na pergunta número",
              preguntaNoImagen: (count: number) => `O número máximo de imagens é ${count} no número da pergunta`,
              preguntaImagen: `Inserir uma imagem no número da pergunta`,
              preguntaBloqueo: "Não é permitido continuar devido à falta de formação técnica na pergunta número",
              preguntaNoImagenGrupal: "Digite uma imagem grupal",
              preguntaNoObservacionGrupal: "Digite uma observação grupal",
              preguntaBlockDevolverOrden: (numero: any) => `Se não tiver o material necessário na pergunta número ${numero}, você não poderá registrar`,
              preguntaModuloAUT: "Você só pode registrar na 'Lista de Autoverificação' se for o único membro da equipe. Os grupais são feitos na 'Conversa Preliminar'",
              preguntaModuloCPT: "Você só pode registrar na 'Conversa Preliminar' se houver vários membros da equipe. Os individuais são feitos na 'Lista de Autoverificação'",
              preguntaJefeTrabajo: "Selecione um Chefe de Trabalho na pergunta número",
              preguntaRiesgo: "Selecione um risco na pergunta número",
              preguntaMedidaPreventiva: "Selecione uma Medida Preventiva na pergunta número",
              preguntaEPCS: "Selecione uma EPCS na pergunta número",
              preguntaRecursoPreventivo: "Selecione o Recurso Preventivo na pergunta número",
              preguntaFirmaJefeTrabajo: "Digite a assinatura do Chefe de Trabalho na pergunta número",
              preguntaFirmaJefeTrabajoVacio: 'Desenhe uma assinatura para salvar',
            }
          }
        },
        comentario: {
          textComentario: 'Comentário',
          titleModalAgregar: 'Adicionar comentário',
          titleModalList: 'Lista de comentários',
          table: {
            autor: 'Autor',
            puntuacion: 'Pontuação',
            Comentario: 'Comentário'
          },
          requiredAutochequeo: 'Você deve primeiro revisar o PDF antes de inserir um comentário',
          requiredCharlaPretarea: 'Você deve primeiro revisar o áudio ou PDF antes de inserir um comentário'
        }
    },
    moduloNotificacion: {
      textoNoLeido: 'Unread',
      textoAcabaRecibirNotificacion: 'Você acaba de receber um novo comentário no registro nº:',
      textoNuevo: 'Novo'
    },
    menu: {
        '010': {
          titulo: 'Inspeções',
          descripcion: 'Registro de Inspeções'
        },
        '020': {
          titulo: 'Inspeções OPS',
          descripcion: 'Registro de Inspeções OPS'
        },
        '030': {
          titulo: 'Lista de Autoverificação',
          descripcion: 'Registro de Lista de Autoverificação'
        },
        '031': {
          titulo: 'Conversa Preliminar',
          descripcion: 'Gravação da Conversa Preliminar'
        },
        '040': {
          titulo: 'Controle Preventivo',
          descripcion: 'Registro de Perguntas de Controle Preventivo'
        },
        '050': {
          titulo: 'RDI',
          descripcion: 'Registro de Incidentes de Segurança'
        },
        '060': {
          titulo: 'Gestão de Autoverificação',
          descripcion: 'Gestão de Autoverificação'
        }
    },
    languages: [
        { code: 'es', name: 'Espanhol' },
        { code: 'en', name: 'Inglês' },
        { code: 'it', name: 'Italiano' },
        { code: 'fr', name: 'Francês' },
        { code: 'pt', name: 'Português' }
    ]      
}