// Library
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

// Components
import ElementAudioCostume from '../../../../shared/Components/ElementAudioCostume';
import { ElementEmptyList } from '../../../../shared/Components/ElementEmptyList';
import { ElementInputFileImage } from '../../../../shared/Components/ElementInputFileImage';
import { InputCheck, InputSelectPersonnel, InputText } from '../../../../shared/Components/ElementInputsCostume';
import ElementViewImagesCostume from '../../../../shared/Components/ElementViewImagesCostume';

// Domain
import { EntityAutochequeoItems, EntityAutochequeoItemsDisponibilidad } from '../../../../shared/Domain/EntityAutochequeoItems';
import { EntityAutochequeoTipologia } from '../../../../shared/Domain/EntityAutochequeoTipologia';
import { EntitySelectPersonal } from '../../../../shared/Domain/EntityPersonal';
import { EntityTipoRiesgo } from '../../../../shared/Domain/Catalogos/EntityTipoRiesgo';
import { EntityOpcionAudio } from '../../../../shared/Domain/Catalogos/EntityOpcionAudio';
import { EntitySelectBase } from '../../../../shared/Domain/EntitySelectBase';
import { EntityEPCS } from '../../../../shared/Domain/Catalogos/EntityEPCS';
import { EntityMedidaPreventivas } from '../../../../shared/Domain/Catalogos/EntityMedidaPreventivas';

// Infraestructure
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { ListFilterOptionSelect } from '../../../../shared/Infraestructure/ListFilterOptionSelect';
import { AdapterConfigure } from '../../Infaestructure/AdapterConfigure';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';

interface ITemplateBasicComponents {
    onChange: (key: string, value: any) => void;
    value: any;
    options?: any[];
    className?: string;
    disabled?: boolean;
    isRequerid?: boolean
    autoCompletePersonal?: () => Promise<EntitySelectPersonal[]>;
}

interface ITemplateEstadoEquipo extends ITemplateBasicComponents{
    estados: EntityAutochequeoItemsDisponibilidad[];
    idGroup: number;
    data: EntityAutochequeoItems;
}

interface ITemplateAccordion extends ITemplateBasicComponents {
    title: string;
    cards: EntityAutochequeoTipologia[];
}

interface ITemplateAudio extends ITemplateBasicComponents {
    opcionAudio: { label: string; value: any; dataComplete: EntityOpcionAudio }[];
}

interface ITemplateImage extends ITemplateBasicComponents {
    countRequiredPhotos?: number;
    showRequiredPhotos: boolean;
}

export const ComponentEstadoEquipo = (props: ITemplateEstadoEquipo) => {
    const translated: any = {
        BIEN: languageTranslate.moduloAutoChequeo.form.inputs.textBien,
        MAL: languageTranslate.moduloAutoChequeo.form.inputs.textMal
    }
    
    return (
        <div className='d-flex mt-2'>
            {
                props.estados.map(item => {
                    const label: string = (item.Respuesta || '').toLocaleUpperCase();
                    const isCheked = (item.Respuesta === props.value) || false;
                    return (
                        <div className='mx-2' key={item.Respuesta}>
                            <label> {translated[label] || item.Respuesta} </label>
                            <input
                                type="radio"
                                name={props.data._id + props.idGroup.toString() + item.Respuesta}
                                value={item.Respuesta}
                                checked={isCheked}
                                disabled={props.disabled}
                                className='radio_custom mx-1'
                                onChange={(evt) => props.onChange('estadoEquipo', evt.target.value)}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

const stylePersonal = {
    minHeight: 27,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    color: 'white',
    border: 'none',
    background: '#39aebc'
}

const languageTranslate = LanguageTranslate();

export const ComponentPersonal = (props: ITemplateBasicComponents) => {
    const prevOnChange = (_:string, value: any) => props.onChange('personal', value);
    const prevSearch = async () => {
        if (!props.autoCompletePersonal) return;
        const response = await props.autoCompletePersonal();
        prevOnChange('', response);
    }
    const prevDelete = (personal: EntitySelectPersonal) => {
        const listOptionsSelect: EntitySelectPersonal[] = props.value.personal || [];
        if (listOptionsSelect.length === 1) return props.onChange('personal', []);

        const findedIndex = listOptionsSelect.findIndex(row => row.value === personal.value);
        listOptionsSelect.splice(findedIndex, 1);
        props.onChange('personal', listOptionsSelect);
    }

    return (
        <>
            <InputSelectPersonnel
                label={languageTranslate.moduloAutoChequeo.form.inputs.txtPersonal}
                name='grupoPreguntas'
                onChange={prevOnChange}
                values={{ grupoPreguntas: props.value.personal }}
                options={props.options}
                arrFilterOption={ListFilterOptionSelect.personal}
                isMulti
                closeMenuOnSelect
                isClearable={false}
                disabled={props.disabled}
                isAsync={true}
                delaySearch={1000}
                className='mt-3 mb-0'
                isRequired
            />

            { props.disabled ? null : <button style={stylePersonal} className='btn btn-primary' onClick={prevSearch}>{languageTranslate.moduloAutoChequeo.form.btnAutcompletarUltimoRegistro}</button> }
                {(props.value.personal || []).length > 0 && (
                <table className="table table-striped mb-5 mt-5">
                    <thead>
                    <tr>
                        <th scope="col">
                        {
                            languageTranslate.moduloInspeccion.form.stepper.stepper3
                            .content.table.id
                        }
                        </th>
                        <th scope="col">
                        {
                            languageTranslate.moduloInspeccion.form.stepper.stepper3
                            .content.table.name
                        }
                        </th>
                        <th scope="col">
                        {
                            languageTranslate.moduloInspeccion.form.stepper.stepper3
                            .content.table.lastName
                        }
                        </th>
                        <th scope="col">
                        {
                            languageTranslate.moduloInspeccion.form.stepper.stepper3
                            .content.table.responsable
                        }
                        </th>
                        {!props.disabled && <th scope="col"></th>}
                    </tr>
                    </thead>
                    <tbody>
                    {props.value.personal.map((psl: EntitySelectPersonal) => (
                        <tr>
                            <th scope="row">{psl.dataComplete.IdPersonal}</th>
                            <td>{psl.dataComplete.Nombres}</td>
                            <td>{psl.dataComplete.Apellidos}</td>
                            <td className="text-center">
                            <input
                                type="radio"
                                disabled={props.disabled}
                                checked={   
                                    psl.value ===
                                    props.value.PersonalResponsableTrabajo?.value
                                }
                                onClick={() =>
                                    props.onChange("PersonalResponsableTrabajo", psl)
                                }
                            />
                            </td>
                            {!props.disabled && (
                            <td onClick={() => prevDelete(psl)}>
                                <i
                                className="fa-solid fa-trash"
                                style={{ fontSize: 15 }}
                                />
                            </td>
                            )}
                        </tr>
                        )
                    )}
                    </tbody>
                </table>
                )}    
        </>
    )
}

export const ComponentListPersonalCheck = (props: ITemplateBasicComponents) => {
    const prevOnChange = (item: EntitySelectPersonal) => {
        const list: any[] = (props.value.RecursoPreventivo || []);
        const findedIndex = list.findIndex((row: any) => row.value === item.value);

        findedIndex !== -1 ? list.splice(findedIndex, 1) : list.push(item);
        props.onChange("RecursoPreventivo", list);
    }

    return (
        <>
            {(props.value.Personal || []).length > 0 && (
                <table className="table table-striped mb-5">
                    <thead>
                    <tr>
                        <th scope="col">
                        {
                            languageTranslate.moduloInspeccion.form.stepper.stepper3
                            .content.table.id
                        }
                        </th>
                        <th scope="col">
                        {
                            languageTranslate.moduloInspeccion.form.stepper.stepper3
                            .content.table.name
                        }
                        </th>
                        <th scope="col">
                        {
                            languageTranslate.moduloInspeccion.form.stepper.stepper3
                            .content.table.lastName
                        }
                        </th>
                        <th scope="col">
                        {
                            languageTranslate.moduloInspeccion.form.stepper.stepper3
                            .content.table.recursoPreventivo
                        }
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.value.Personal.map((psl: EntitySelectPersonal) => (
                        <tr>
                            <th scope="row">{psl.dataComplete?.IdPersonal}</th>
                            <td>{psl.dataComplete.Nombres}</td>
                            <td>{psl.dataComplete.Apellidos}</td>
                            <td className="text-center">
                            <input
                                type="radio"
                                disabled={props.disabled}
                                checked={   
                                    props.value.RecursoPreventivo?.find((row: any) => row.value === psl.value)
                                }
                                onClick={() => prevOnChange(psl)}
                            />
                            </td>
                        </tr>
                        )
                    )}
                    </tbody>
                </table>
            )}
        </>
    )
}

export const ComponentObservacion = (props: ITemplateBasicComponents) => {
    const languageTranslate = LanguageTranslate();
    const prevOnChange = (_:string, value: string) => props.onChange('observacion', value);
    return ( <InputText className='mt-3 mb-0' label={languageTranslate.moduloAutoChequeo.form.inputs.txtObservacion} disabled={props.disabled} name='observacion' onChange={prevOnChange} values={{ observacion: props.value || '' }} isRequired={props.isRequerid} /> )
}

export const ComponentAudio = (props: ITemplateAudio) => {
    const prevOnChangeAudio = (_:string, value: Blob) => props.onChange('charlaPreTarea', value);
    const prevOnChangeList = (name:string, value: any) => {
        props.onChange('opcionAudio', {...(props.value.opcionAudio || {}), [name]: value });
    }

    return (
        <>
            <ElementAudioCostume onChange={prevOnChangeAudio} name='' value={props.value.charlaPreTarea} disabled={props.disabled}/>
            <ul>
                {
                    (props.opcionAudio || []).map(row =>
                        <li>
                            <InputCheck
                                disabled={props.disabled}
                                label={row.label}
                                name={row.value}
                                values={props.value?.opcionAudio || {}}
                                onChange={prevOnChangeList}
                            />

                            <ul className='mb-3'>
                                {
                                    row.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.helperEPCS ?
                                        props.value.EPCS.map((row: EntitySelectBase<EntityEPCS>) =>
                                            row.dataComplete.opcionPregunta.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroEpcs ?
                                            props.value.otroEPCS
                                            :
                                            <li> { row.label || row.dataComplete?.iteminIdioma?.find(_row => _row.codigo === languageTranslate.code)?.nombre || row.dataComplete?.iteminIdioma?.find(_row => _row.codigo === 'es')?.nombre || '' } </li>
                                        )
                                    : null
                                }
                            </ul>
                            <ul className='mb-3'>
                                {
                                    row.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.helperMedidasPreventivas ?
                                        props.value.MedidasPreventivas.map((row: EntitySelectBase<EntityMedidaPreventivas>) =>
                                            <li>
                                                {
                                                    row.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtroMedidasPreventivas ?
                                                        props.value.otroMedidasPreventivas
                                                        :
                                                        row.label || row.dataComplete?.iteminIdioma?.find(_row => _row.codigo === languageTranslate.code)?.nombre || row.dataComplete?.iteminIdioma?.find(_row => _row.codigo === 'es')?.nombre || ''
                                                }
                                            </li>
                                        )
                                    : null
                                }
                            </ul>
                            <ul className='mb-3'>
                                {
                                    row.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.helperTipoRiesgo ?
                                        props.value.Riesgos.map((row: EntitySelectBase<EntityOpcionAudio>) => 
                                            <li>
                                                {
                                                    row.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtrosRiesgos ? 
                                                        props.value.otroRiesgo
                                                        :
                                                        row.label || row.dataComplete.iteminIdioma.find(_row => _row.codigo === languageTranslate.code)?.nombre || row.dataComplete.iteminIdioma.find(_row => _row.codigo === 'es')?.nombre || ''
                                                    
                                                }
                                            </li>
                                        )
                                    : null
                                }
                            </ul>

                        </li>
                    )
                }
            </ul>
        </>
    )
}

export const ComponentImage = (props: ITemplateImage) => {
    const languageTranslate = LanguageTranslate();
    const [show, setShow] = useState(false);

    const prevOnChange = (_:string, files: File[]) => {
        const images: { type: string; valueToShow: string; value: any; }[] = props.value || [];
        Array.from(files).forEach(file => {
            images.push({
                type: 'Local',
                valueToShow: (window.URL ? URL : webkitURL).createObjectURL(file),
                value: { path: file.name, file: file }
            })
        });

        props.onChange('images', images);
    }

    const prevOnChangeDelete = (indexDelete: number) => {
        const images: { type: string; valueToShow: string; value: any; }[] = props.value || [];
        images.splice(indexDelete, 1);
        props.onChange('images', images);
    }

    return (
        <div className={`form-row d-flex ${props.className || ''}`}>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
                <div className='d-flex align-self-center'>
                    <span className="camera"> <ElementInputFileImage icon={'fa-solid fa-camera'} capture onChange={(file) => prevOnChange('', file)} disabled={props.disabled} /> </span>
                    <span className="image"> <ElementInputFileImage icon={'fa-solid fa-image'} multiple onChange={(file) => prevOnChange('', file)} disabled={props.disabled} /> </span>
                </div>
                { props.showRequiredPhotos ? <span className='text-muted' style={{ fontSize: '0.7rem' }}>(Max. {props.countRequiredPhotos})</span> : null }
            </div>
            {(props.value || []).length !== 0 && <button className='btn btn-dark' style={{ marginLeft: 'auto', height: 38 }} onClick={() => setShow(true)}> {languageTranslate.btnVerImagen} ({props.value?.length}) <i className="fa-solid fa-eye" style={{ fontSize: 20, marginLeft: 10 }} /> </button>}
            <ElementViewImagesCostume closeModal={() => setShow(false)} images={props.value || []} show={show} deleteImage={prevOnChangeDelete} disabled={props.disabled} />
        </div>
    )
}

export const ComponentAccordion = (props: ITemplateAccordion) => {
    const [show, setShow] = useState(true);
    const prevOnChange = (value: EntityAutochequeoTipologia) => {
        const arr:EntityAutochequeoTipologia[] = props.value.Tipologia || [];
        const indexFinded = arr.findIndex(item => item._id === value._id);
        indexFinded === -1 ? arr.push(value) : arr.splice(indexFinded, 1);
        props.onChange('Tipologia', arr);
    }

    const imageDynamic = (codeTipologia: string) => {
        switch(codeTipologia) {
            case "PT": return require('./../../../../../assets/img/TipologiaPostePT.png');
            case "ZANESC": return require('./../../../../../assets/img/TipologiaZanjas.png');
            default: return require('./../../../../../assets/img/TipologiaDefault.png');
        }
    }

    return (
        <div className="accordion mb-4">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button onClick={() => setShow(!show)} className={`accordion-button ${show ? '' : 'collapsed'} bg-white`} type="button" data-bs-toggle="collapse" aria-expanded="true">
                        {props.title}
                    </button>
                </h2>
                <div className={`accordion-collapse collapse ${show ? 'show' : ''}`}>
                    <div className="accordion-body px-2" style={{ background: '#ececec' }}>

                        {
                            props.cards.length === 0 && <ElementEmptyList text='Sin Tipologías' />
                        }

                        {
                            props.cards.map(item => {
                                const isSelected = (props.value.Tipologia as EntityAutochequeoTipologia[] || []).find(tpl => tpl._id === item._id)
                                return (
                                    <div className="card mb-3" key={item._id} onClick={() => prevOnChange(item)}>
                                        <div className="d-flex" style={{ height: 74 }}>
                                            <div style={{ width: 74 }}>
                                                <img src={imageDynamic(item.CodigoTipologia)} style={{objectFit: 'cover', height: '100%', width: '100%'}} className="img-fluid rounded-start" alt="..." />
                                            </div>
                                            <div className="w-100 px-2" style={{ placeSelf: 'center' }}>
                                                <div>
                                                    <label className="mb-0" style={{ fontSize: 14 }}>{item.TipologiaTrabajo}</label>
                                                    <p className="card-text text-muted" style={{ fontSize: 12.5 }}>{item.InformacionTipologia}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`border rounded-circle position-absolute ${isSelected ? 'bg-primary' : 'bg-white'} d-flex`} style={{ height: 25, width: 25, right: -10, top: -10, justifyContent: 'center', alignItems: 'center' }}>
                                            <i className="fa-solid fa-check" style={{ fontSize: 14, color: 'white' }} />
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export const ComponentRiesgos = (props: ITemplateBasicComponents) => {
    const onChange = (value: { label: string; value: any; dataComplete: EntityTipoRiesgo }, mode: 'Si' | 'No') => {
        props.onChange('riesgos', { ...(props.value.riesgos || {}), [value.dataComplete.IdTipoRiesgo]: { value: mode, dataComplete: value.dataComplete } });
    }

    return (
        <div className='d-flex mt-2 flex-column w-100'>
            {
                props.options?.map((row, index) => {
                    const itemSelect = (props.value.riesgos || {})[row.dataComplete.IdTipoRiesgo];
                    // if (props.disabled && itemSelect?.value === 'No') return null;

                    return (
                        <div className='ItemRiesgos mb-3'>
                            <span className='mb-1 text-muted'>{row.label}</span>
                            <div>
                                <button disabled={props.disabled} className={`btn ${itemSelect?.value === 'Si' ? 'btn-primary' : 'btn-light'}`} onClick={() => onChange(row, 'Si')}>{languageTranslate.btnSi}</button>
                                <i className={row.icon} style={{ fontSize: 20}} />
                                <button disabled={props.disabled} className={`btn ${itemSelect?.value === 'No' ? 'btn-primary' : 'btn-light'}`} onClick={() => onChange(row, 'No')}>{languageTranslate.btnNo}</button>
                            </div>
                            {
                                row.dataComplete?.opcionPregunta?.codigo === AdapterConfigure.KEYS_FORM.specialInputs.indicarOtrosRiesgos && itemSelect?.value === 'Si' ?
                                <InputText
                                    label=''
                                    name='otroRiesgo'
                                    values={props.value}
                                    onChange={props.onChange}
                                    disabled={props.disabled}
                                />
                                : null
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export const ComponentFirma = (props: ITemplateBasicComponents) => {
    const signatureRef = useRef<any>({});
    const [showFirma, setShowFirma] = useState(false);

    // Verificar si el lienzo está vacío
    const isCanvasBlank = (canvas: any) => {
        const ctx = canvas.getContext('2d');
        const pixelBuffer = new Uint32Array(
        ctx.getImageData(0, 0, canvas.width, canvas.height).data.buffer
        );
        return !pixelBuffer.some(color => color !== 0);
    };

    const getImage = () => {
        const canvas = signatureRef.current.getCanvas();
        if (isCanvasBlank(canvas)) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloAutoChequeo.form.validate.formDinamico.preguntaFirmaJefeTrabajoVacio, 'error');;
            return;
        };

        canvas.toBlob((Blob: Blob | null) => {
            if (!Blob) return;
            const file = new File([Blob], "firma.png", { type: "image/png" });
            props.onChange('firma', file);
        });
    }


    const deleteImage = () => {
        signatureRef.current?.clear();
        props.onChange('firma', null);
    }

    return (
        <>
            {
                !props.disabled ?
                    <>
                        <div className='d-flex' style={{
                            justifyContent: 'right',
                            gap: 10,
                            marginBottom: 10
                        }}>
                            <button className='btn btn-primary' onClick={deleteImage}>{languageTranslate.btnLimpiar}</button>
                            <button className='btn btn-primary' onClick={getImage}>{languageTranslate.btnGuardarFirma}</button>
                        </div>
                        {
                            !props.value.firma ? 
                                <div className='d-flex justify-content-center'>
                                    <SignatureCanvas
                                        penColor='black'
                                        canvasProps={{ height: 260, width: 280 }}
                                        ref={signatureRef}
                                    />
                                </div>
                            : null
                        }
                    </>
                : null
            }

            {
                (props.value.firma || props.disabled) ?
                    <button className='btn btn-primary w-100 mt-3' onClick={() => setShowFirma(true)}>{languageTranslate.btnVerFirma}</button>
                : null
            }

            <ElementViewImagesCostume closeModal={() => setShowFirma(false)} images={props.value.firma ? [{ type: 'Local', value: props.value.firma, valueToShow: props.disabled && typeof props.value.firma === 'string' ? props.value.firma : (window.URL ? URL : webkitURL).createObjectURL(props.value.firma) }] : []} show={showFirma} deleteImage={deleteImage} disabled={props.disabled} />
        </>
    )
}