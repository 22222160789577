import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityLoadData } from "../Domain/Utils";

export class UseCaseLoadData {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(permisoVariables: {
        arrIdPaises: Array<number>;
        arrIdGrupos: Array<number>;
        arrIdDelegaciones: Array<number>;
        arrIdOT: Array<number>;
      }): Promise<EntityLoadData> {
        return await this.repository.loadData(permisoVariables);
    }
}