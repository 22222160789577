import { EntityLanguage } from "../context/shared/Domain/EntityLanguage";

/* NOTA: modificar solo el valor, no la llave */
export const idioma_fr: EntityLanguage = {
    code: 'fr',
    nombreProyecto: 'Gestionnaire intégral de Administration',
    textoLogin: 'LOGIN',
    textoConfiguracion: 'Configuration',
    textoCatalogos: 'Actualiser les données',
    textoCerrarSesion: 'Déconnexion',
    textoModoCrear: 'CREATE',
    textoModoEditar: 'EDIT',
    textoModoVisualizar: 'AFFICHIER',
    textoModoLevantamiento: 'RÉVOLTE',
    textoNavbarInicio: 'Début',
    textoConfigurandoAreaTrabajo: "Configuration de votre environnement de travail...",
    textoSincronizacionSemanalCatalogo: "Synchronisation hebdomadaire du catalogue",
    textoNuevaVersion: 'Nouvelle version, cliquez ici pour mettre à jour',
    textoGuardando: 'Enregistrer...',
    textoCargando: 'Chargement...',
    textoAlerta: 'Alerte',
    textoIncompleto: 'Incomplet',
    textoExitoso: 'Réussi',
    textoSinConexion: 'Sans connexion',
    textoDescripcionSinConexion: `N'a pas de connexion Internet`,
    textoValidacionTerminos: 'Vous devez accepter les termes',
    textoCambioPais: 'Changement de pays en',
    textoPagina: 'Page',
    textoPaginaDe: 'de',
    textoNro: 'Nro.',
    btnCancelar: 'Annuler',
    btnGuardar: 'Enregistrer',
    btnSiguiente: 'Suivent',
    btnVerImagen: 'Voir les images',
    btnRegresar: 'Retour',
    btnAgregar: 'Ajouter',
    textoCargandoAudio: 'Le traitement de l’audio, en fonction de la durée, peut prendre plus de temps...',
    btnDetener: 'Pause',
    btnReproducir: 'Écouter',
    btnLimpiar: 'Effacer',
    btnGuardarFirma: 'Enregistrer la Signature',
    btnVerFirma: 'Voir la Signature',
    btnSi: 'Oui',
    btnNo: 'Non',
    btnBuscar: 'Trouver',
    btnSeleccionar: 'Choisir',
    btnRecursoPreventivo: 'Ressource Préventive',
    btnDescargar: 'Télécharger',
    btnDescargando: 'Téléchargement...',
    moduloConfiguracion: {
        textoPreferencia: 'Préférence',
        textoPais: 'Pays',
        textoDelegacion: 'Délégation',
        textoEmpresa: 'Compagnie',
        textoOT: 'TO',
        textoIdioma: 'Langue',
        textoCambioPassword: 'Changer de mot de passe',
        textoPassword: 'Mot de passe',
        textoNuevaPassword: 'Nouveau mot de passe',
        textoRepetirNuevaPassword: 'Répéter le nouveau mot de passe',
        textoBotonSaveCambioPassword: 'Enregistrer',
        textoPersonalMediosHumanos: 'Personnel Ressources Humaines',
        textoBuscarCuadrillaPersonal: 'Rechercher un équipage personnel',
        textoAutomatizaPersonal: 'Automatise les ressources humaines',
        validate: {
            formPreferencia: {
                pais: 'Choisissez un pays',
                delegacion: 'Sélectionnez une délégation',
                ot: 'Sélectionnez un TO',
                personal: 'Sélectionnez un personnel des médias humains'
            },
            formChangePassword: {
                password: 'Entrer le mot de passe',
                newPassword: 'Entrez un nouveau mot de passe',
                reNewPassword: 'Entrez Répéter le nouveau mot de passe',
                formatNewPassword: 'Votre nouveau mot de passe doit comporter au moins 12 caractères et inclure au moins une lettre majuscule, un chiffre et un caractère spécial'
            },
            cambioPasswordCorrecto: 'Le changement de mot de passe a réussi'
        }
    },
    moduloLogin: {
        textoTitulo: 'LOGIN',
        txtUsuario: 'Utilisateur',
        txtPassword: 'Mot de passe',
        btnLogin: 'LOGIN',
        chkRecordarUsuario: 'Se souvenir de moi',
        textoOlvidoContrasenia: '¿Vous avez oublié votre mot de passe?',
        textoRegresarMain: `Retour à l'accueil`,
        textoRegistrarRDI: 'Connectez-vous en tant qu’invité',
        textoTitleSelectPais: 'Choisissez le pays',
        textoPlaceholderSelectPais: 'Pays',
        textoErrorSelectPais: 'Vous devez sélectionner une option',
        validate: {
            formLogin: {
                username: `Entrez l'utilisateur`,
                usernameMinimo: '3 caractères minimum',
                password: 'Entrer le mot de passe',
                passwordMinimo: '3 caractères minimum'
            }
        }
    },
    moduloRecuperarPassword: {
        textoTitulo: 'Recupérer le mot de passe',
        textoUsuario: 'Utilisateur',
        textoIdentificacion: `Numéro d'identification`,
        textoCorreoElectronico: 'Courriel',
        textoAccion: 'Récupérer',
        textoRegresarLogin: 'Renvoyer le Login',
        validate: {
            form: {
                username: `Entrez l'utilisateur`,
                usernameMinimo: '3 caractères minimum',
                identificacion: `Saisir le numéro de pièce d'identité`,
                identificacionFormato: `Le numéro de pièce d'identité est incorrect`,
                correoElectronico: `Entrez l'e-mail`,
                correoElectronicoFormato: `L'e-mail est erroné`
            },
            recuperarPasswordCorrecto: `La récupération du mot de passe a réussi, vous recevrez un message à l'adresse e-mail enregistrée`
        }
    },
    modulo404: {
        textoDescripcion: `La ressource demandée n'est pas disponible`,
        textoHome: `Retourner à la page d'accueil`
    },
    moduloNotAvailable: {
        textoCabecera: 'Ressource non disponible',
        textoDescripcion: `La ressource demandée n'est pas disponible pour votre appareil`,
    },
    moduloMain: {
        textoTitulo: 'Système de gestion de la sécurité opérationnelle',
        textoBienvenida: '¡Nous vous souhaitons la bienvenue!',
        textoInicio: 'Démarrer',
        textoAyuda: 'Aide',
        textoTituloPreguntaAyuda: 'Redirection',
        textoDescripcionPreguntaAyuda: `Vous allez être redirigé vers la documentation d'aide du système, ¿êtes-vous sûr?`,
        textoReporte: 'Repporter',
        textoRefrescarApp: 'Actualiser l’application',
        textoNotificacion: 'Notification',
        textoTituloPreguntaReporte: 'Redirection',
        textoDescripcionPreguntaReporte: 'Vous allez être redirigé vers le système de gestion des services, ¿êtes-vous sûr?',
    },
    moduloCatalogo: {
        textoRefrescar: 'Rafraîchir',
        textoRefrescarTodo: 'Rafraîchir tout',
        textoSincronizado: 'Statut : Synchronisé',
        textoNoSincronizado: 'Statut : Non synchronisé',
    },
    moduloRDI: {
        list: {
            navbar: [{ text: 'EDI', navigate: true, path: '/main/RDI' }, { text: 'Liste', navigate: false, path: '' }],
            btnAgregar: 'AJOUTER',
            textoVacio: 'Aucune information',
            textoEstado: 'Statut',
            validate: {
                formFilter: {
                    fechaInicio: 'Entrez la date de début',
                    fechaFin: 'Entrez la date de fin'
                }
            }
        },
        form: {
            navbar: [{ text: 'EDI', navigate: true, path: '/main/RDI' }, { text: 'Liste', navigate: false, path: '' }],
            textoPais: 'Pays',
            textoDelegacion: 'Délégation',
            textoEmpresa: 'Entreprise',
            textoSubcontrata: 'Sous-traitance',
            textoCliente: 'Client',
            textoActividad: 'Activité',
            textoUsuarioRegistra: 'Registre des utilisateurs',
            textoFechaHoraIncidencia: `Date et heure de l'incident`,
            textoTipoIncidencia: `Type d'incident`,
            textoDescripcionIncidencia: 'Désignation Incident',
            textoMedidasPropuestas: 'Solution proposée',
            textoDescripcionSolucion: 'Description de la solution',
            textoFotoSubsanacion: 'Correction photo',
            textoUsuarioMedidasPropuestas: `Gestionnaire de solutions`,
            textoObra: 'Chantier de construction',
            textoProvincia: 'Province',
            textoDireccion: 'Adresse',
            textoFactorRiesgo: 'Facteur de risque',
            textoTipoIncidente: `Propre / Sous-traitance`,
            textoParalizacionTrabajo: 'Arrêt de travail',
            textoCondicionInsegura: 'Situation dangereuse',
            textoVehiculoAfectado: 'Véhicule concerné',
            textoVerImagenes: `Voir l'image`,
            textoPlaca: `Plaque d'immatriculation du véhicule concerné`,
            textMensajeAgradecimiento: 'Le RDI a été enregistré avec succès et a déjà été communiqué au reste des personnes impliquées. Merci beaucoup d’avoir signalé cet incident, avec votre rapport vous aidez à améliorer votre sécurité et celle de vos collègues',
            textoFotosIncidencia: 'Photos de l’incident',
            textoAyudaSolucion: 'Remplissez uniquement si le problème est résolu',
            modal: {
                textoTitulo: 'Image',
                btnEliminarImagen: `SUPPRIMER L'IMAGE`,
                textoSinImagenes: `Pas d'image`
            },
            btnNext: 'Suivant',
            btnSave: 'Sauvegarder',
            stepper: {
                stepper1: {
                    text: 'Informations générales',
                    content: {
                        title: 'INFORMATIONS GÉNÉRALES',
                    }
                },
                stepper2: {
                    text: `Détail de l'incidence`,
                    textDetalleSolucion: 'Mesures proposées',
                    textSolucion: 'Solution',
                    content: {
                        title: `DÉTAIL DE L'INCIDENT`,

                    }
                }
            },
            validate: {
                formRegistro: {
                    ot: 'Sélectionnez un TO',
                    delegacion: 'Sélectionnez une délégation',
                    empresa: 'Sélectionnez une entreprise',
                    cliente: 'Sélectionnez un client',
                    actividad: 'Sélectionnez une activité',
                    usuarioRegistro: 'Sélectionnez un utilisateur enregistré',

                    fechaIncidente: `Sélectionnez une date et une heure d'incident`,
                    tipoIncidencia: `Sélectionnez un type d'incident`,
                    breveDescripcionIncidente: `Saisir une description d'incident`,
                    factorRiesgo: `Sélectionnez au moins un facteur de risque`,
                    tipoIncidente: `Sélectionnez au moins un type d'incident`,
                    condicionInsegura: `Sélectionnez une condition dangereuse`,
                    vehiculoAfectado: `Sélectionnez le véhicule concerné`,
                    cantidadImagenes: 'Le nombre maximum d’images autorisé est de 10',

                    propuestaSolucion: 'Entrez une proposition de solution',
                    descripcionSolucion: 'Entrez une description de solution',
                    responsableSolucion: 'Entrez un responsable de solution',
                    fotoSolucion: 'Entrez une photo de remédiation',

                    fotoIncidencia: 'Téléchargez une image de l’incident',
                    fotoSolucionRequerimientroPrevioFotoIncidencia: 'Vous devez d’abord télécharger une photo de l’incident'
                }
            },
            TipoIncidentePersonal: "Personnel propre",
            TipoIncidenteSubContrata: "Personnel sous-traité"
        }
    },
    moduloInspeccion: {
        list: {
            navbar: [{ text: 'Inspections', navigate: false, path: '' }],
            navbarOPS: [{ text: 'OPS', navigate: false, path: '' }],
            btnAgregar: 'AJOUTER',
            textoVacio: 'Aucune inspection',
            textoVacioOPS: 'Aucune inspection OPS',
            textoEnviandoCorreo: 'Envoi de mail...',
            modalEmail: {
                title: 'Envoyer du courrier',
                correoElectronico: 'Courrier électronique',
                tableEmail: 'POSTER',
                cc: 'CC',
                btnEnviar: 'Envoyer'
            },
            modalSearch: {
                titulo: 'Recherche personnalisée',
                fechaInicial: 'Date de début',
                fechaFinal: 'Date de clôture',
                tipoOperacion: 'Type d’opération',
                identificacion: 'Identification',
                buscarInspeccionesFinalizadas: 'Recherche d’inspections terminées',
                codigoInspeccion: 'Code d’inspection',
                btnBuscar: 'Trouver'
            },
            item: {
                jefeObra: 'Chef de chantier',
                autor: 'Auteur',
                nroNoConformidad: 'No. Non-conformité',
                fechaInspeccion: `Date d'inspection`,
                empresa: 'Entreprise',
                subContrata: 'Sous-traitance',
                tipoOperacion: `Type d'opération`,
                personal: 'Personnel'
            },
            validate: {
                correoExiste: `Le courrier existe déjà`,
                correoEnviado: `Bien envoyé`,
                fechaInicio: 'Entrez la date de début',
                fechaFinal: 'Entrez la date de fin',
                fechaRangoInicioFinal: 'La date de début ne doit pas être supérieure à la date finale',
                tipoOperacion: 'Entrez au moins un type d’opération',
                identificacion: 'Entrez l’ID',
            }
        },
        form: {
            navbar: [{ text: 'Inspections', navigate: true, path: '/main/Inspeccion' }],
            navbarOPS: [{ text: 'OPS', navigate: true, path: '/main/OPS' }],
            navbarText: {
                modeCrear: 'Créer',
                modeVisualizar: 'Visualiser',
                modeLevantamiento: 'Levage'
            },
            btnNext: 'Suivant',
            btnSave: 'Sauvegarder',
            stepper: {
                stepper1: {
                    text: 'Informations générales',
                    content: {
                        title: 'INFORMATIONS GÉNÉRALES',
                        ot: 'TO',
                        area: 'Zone / Filiale',
                        empresa: 'Entreprise',
                        actividad: 'Activité',
                        cliente: 'Client',
                        jefeObra: 'Chef de chantier',
                        coordinador: 'Technicien en sécurité',
                        encargado: 'Responsable'
                    }
                },
                stepper2: {
                    text: `Informations sur l'inspection`,
                    content: {
                        title: `INFORMATIONS SUR L'INSPECTION`,
                        responsableLevantamiento: 'Levage responsable',
                        inspeccionConjunta: 'Contrôle conjoint',
                        personalConjunta: 'État-major interarmées',
                        subContrata: 'Sous-traitance',
                        inspeccionSubcontrataConjunta: 'Inspection commune de la sous-traitance',
                        tipoOperacion: `Type d'opération`,
                        formato: 'Format',
                        paralizacionTrabajo: 'Arrêt de travail',
                        momento: 'Moment',
                        lugar: 'Lieu',
                        obraTD: 'Travail / TD',
                        trabajoRealizar: 'Travail à faire',
                        placa: 'Plaque / Plaque d’immatriculation',
                        reconocimientoSST: 'Reconnaissance de la bonne performance en matière de SST',
                        observacionOPS: 'Observation de l’OPS',
                        fueraHorario: 'Après les heures d’ouverture',
                    }
                },
                stepper3: {
                    text: 'Information personnelle',
                    content: {
                        title: 'INFORMATION PERSONNELLE',
                        personal: 'Personnel',
                        table: {
                            id: 'ID',
                            name: 'nom',
                            lastName: 'Noms de famille',
                            responsable: 'Gestionnaire d’emploi',
                            recursoPreventivo: 'Appel préventif',
                        }
                    }
                },
                stepper4: {
                    text: 'Informations Inscription',
                    content: {
                        details: {
                            title: `TYPE D'INSPECTION`,
                            btnSelect: 'Sélectionner',
                            listEmpty: `Aucun type d'inspection sélectionné`,
                            titleModal: `Sélectionnez le type d'inspection`,
                            titleModalView: 'Informations sur l’article',
                            titleModalCreate: 'Créer un élément',
                            titleModalEdit: 'Modifier l’élément'
                        },
                        items: {
                            title: 'LISTE DES ARTICLES',
                            btnList: 'Liste',
                            btnAdd: 'Ajouter',
                            listEmpty: `Pas d'enregistrements`,
                            modal: {
                                tipoInspeccion: `Type d'inspection`,
                                tipoItem: `Type d'élément`,
                                item: 'élément',
                                detalle: 'Détail',
                                personal: 'Personnel',
                                resultado: 'Résultat',
                                nivelGravedad: 'Degré de gravité',
                                tipoSituacion: 'Type Situation',
                                grave: 'Grave',
                                descripcion: 'Description',
                                accionPropuesta: 'Action proposée',
                                levantamientoInmediato: 'Lifting immédiat',
                                descripcionLevantamiento: 'Description Enquête',
                                fechaLevantamiento: 'Date de l’enquête',
                                imgTextoInspeccion: 'Inspection',
                                imgTextoLevantamiento: 'Levage',
                                titleModalImage: 'Voir les photos',
                                fechaPrevista: 'Date prévue',
                                btnSave: 'Sauvegarder',
                                btnSaveAndContinue: 'Sauvegarder et continuer',
                                btnCancel: 'Annuler'
                            },
                            item: {
                                tipoInspeccion: `Type d'inspection`,
                                tipoItem: `Type d'élément`,
                                item: 'élément',
                                descripcion: 'Description',
                                textIncompleta: 'incomplet',
                                textCorrecto: `C'est Correct`,
                                textGrave: 'Grave',
                                textNoConforme: 'Non conforme',
                                textImgInspeccion: 'Inspection'
                            }
                        },
                        textoCompruebaInformacion: 'Vérifiez vos informations à l’adresse suivante :',
                        textObservacionGeneral: 'Observation générale',
                    }
                }
            },
            validate: {
                formRegistro: {
                    ot: 'Sélectionnez un TO',
                    area: 'Sélectionnez une zone',
                    empresa: 'Seleccione una Empresa',
                    actividad: 'Sélectionnez une activité',
                    cliente: 'Sélectionnez un client',
                    jefeObra: 'Sélectionnez un gestionnaire de site',
                    coordinador: 'Sélectionnez un coordinateur',

                    conjuntaPersonal: 'Sélectionnez un bâton dans Joint',
                    tipoOperacion: `Sélectionnez un type d'opération`,
                    formato: 'Sélectionnez un format',
                    tipoMomentoTrabajo: 'Sélectionnez un type de moment',
                    lugar: 'Entrer un lieu',
                    obra: 'Saisir une œuvre',
                    trabajoRealizar: 'Entrez un travail à faire',
                    observacionOPS: `Entrer dans une OPS d'observation`,

                    personalResponsableTrabajo: 'Sélectionnez un gestionnaire de tâches',

                    detalleInspeccionVacia: 'Saisissez au moins un élément',
                    detalleInspeccionIncompleta: 'Complétez tous les éléments',
                    responsableLevantamiento: 'Sélectionnez un responsable',

                    cantidadImagenes: (cantidadMaxima: any) => `Le nombre maximum d'images autorisé est ${cantidadMaxima}`, 
                },
                formRegistroItem: {
                    tipoInspeccion: `Sélectionnez le type d'inspection`,
                    tipoItem: `Sélectionnez le type d'élément`,
                    item: 'Sélectionner un élément',
                    detalle: 'Sélectionnez Détail',
                    personal: 'Sélectionnez une portée',
                    resultado: 'Sélectionnez un résultat',
                    nivelGravedad: 'Sélectionnez le niveau de gravité',
                    descripcion: 'Sélectionnez une description',
                    accionPropuesta: 'Saisir une action proposée',
                    tipoSituacion: 'Sélectionnez un type de situation',
                    descripcionLevantamiento: `Entrez une description de l'enquête`,
                    fechaLevantamiento: 'Entrez une date de levée de fonds',
                    fotoLevantamiento: 'Entrer une image de sondage',
                    cantidadImagenes: 'Toutes les images sont-elles nécessaires ? (vérifiez qu’aucune image n’est répétée)',
                    fechaPrevista: 'Entrez une date prévue',
                },
                noItem: 'Objet non-trouvé',
                noDetalleItem: `Détail de l'article introuvable`,
                detalleItemDuplicado: `Détail de l'article en double`,
                detalleItemNoPermitido: 'Article non autorisé à être ajouté',
                noTipoInspeccion: `Vous devez sélectionner un type d'inspection pour compléter automatiquement`
            },
            TipoMomento: {
                inicio: 'Début des travaux',
                durante: 'Pendant les travaux',
                fin: 'Fin des travaux'
            },
            textoBuscarCuadrillaPersonal: `Recherche personnel d'equipe...`,
            textoCorrecto: "Correct",
            textoNoConforme: "Non-conformité",
            nivelSeguridad: {
                textoAlta: "Élevée",
                textoMedia: "Moyenne",
                textoLeve: "Douce",
            },
            tipoSituacion: {
                textoActoInseguro: "Acte Dangereux",
                textoCondicionInsegura: "Condition Dangereuse"
            }
        }
    },
    moduloAutoChequeo: {
        list: {
            TituloListaAutochequeo: `LISTE D'AUTO-VÉRIFICATION`,
            TituloListaControlPreventivo: "CONTRÔLE PRÉVENTIF",
            navbar: [{ text: `Gestion de l'autocontrôle`, navigate: false, path: '' }],
            navbarGuest: [{ text: 'Entretien préalable à la tâche', navigate: false, path: '' }],
            Estado: "Statut",
            textoCPPendiente: 'CP en attente',
            EstadoItems: {
                EstadoActivo: "Actif",
                EstadoInactivo: "Inactif"
            },
            Resultado: "Résultat",
            ResultadoItems: {
                ResultadoCorrecto: `C'est Correct`,
                ResultadoIncorrecto: "Incorrect"
            },
            Autor: "Auteur",
            FechaRegistro: `Date d'inscription`,
            textAudio: 'Audio',
            textPDF: 'PDF',
            search: {
                Titulo: `Recherche d'autocontrôle`,
                FechaInicial: "La date initiale",
                FechaFinal: "Date finale",
                Identificacion: "IDENTIFIANT",
                BtnBuscar: "Chercher",
                BusquedaGrupal: "Recherche de groupe",
                BuscarRegistrosActivos: "Rechercher des documents actifs"
            },
            validate: {
                formFilter: {
                    fechaInicial: 'Sélectionnez une date de début',
                    fechaFinal: 'Sélectionnez une date de fin',
                    identificacion: 'Entrez un identifiant'
                }
            }
        },
        form: {
            navBarTextCharlaPretarea: 'Discussion préalable à la tâche',
            navBarTextListaAutochequeo: `Liste d'auto-vérification`,
            navBarTextControlPreventivo: 'Contrôle préventif',
            navBarTextCierreControlPreventivo: 'Fermeture du contrôle préventif',
            navBarTextPreview: 'Visualiser',
            navBarGuest: [{ text: 'Entretien préalable à la tâche', navigate: true, path: '/main/GestionAutochequeo' }, { text: 'Détail', navigate: false, path: '' }],
            tituloDatosGenerales: 'Données générales',
            tituloDatosControlPreventivo: 'Contrôle préventif des données',
            textoAyudaControlPreventivo: "Contient une fermeture de contrôle préventif",
            textoAyudaNecesitaRegistrarLAutochequeo: "Cet OT a besoin que la liste d'auto-vérification soit faite en premier, s'il a ignoré ce message",
            btnAutcompletarUltimoRegistro: 'Saisie semi-automatique à partir du dernier enregistrement',
            inputs: {
                ot: 'TO',
                empresa: 'Entreprise',
                subContrata: 'Sous-traitance',
                delegacion: 'Délégation',
                actividad: 'Activité',
                recursoPreventivo: 'Ressource préventive',
                cliente: 'Client',
                obra: 'Chantier de construction',
                lugar: 'Lieu',
                trabajoRealizar: 'Travail à faire',
                jefeObra: 'Chef de chantier',
                tituloGrupo: 'Informations par groupe',
                ordenServicio: 'Service de commande / Demande de service',
                txtObservacion: 'Observation',
                txtPersonal: 'Personnel',
                textFirmaJefeTrabajo: 'Signature du Chef de Travail',
                textBien: 'Bien',
                textMal: 'Mal'
            },
            preguntarRealizarCierreControlPreventivo: 'Vous souhaitez effectuer la Clôture du Contrôle Préventif ?',
            advertenciaListaAutochequeoRealizado: `La liste d'auto-contrôle a déjà été faite`,
            advertenciaSinRegistro: 'Enregistrement non trouvé',
            advertenciaRegistroConCierre: 'Cet enregistrement a déjà une fermeture',
            advertenciaCargarOT: `Une erreur s'est produite lors du chargement des OT`,
            advertenciaBuscarOTRegistro: `WO lié à l'enregistrement introuvable`,
            advertenciaRegistroSinCierre: `Cet TO n'a pas de fermeture de contrôle préventif`,
            advertenciaOTSinTipologias: `Cet TO n'a pas de typologies`,
            advertenciaOTSinPreguntas: `Cet TO n'a pas de questions`,
            advertenciaOTSinPreguntasAutochequeo: `Cet TO n'a pas de questions à montrer, essayez-en un autre`,
            advertenciaOTPreview: (text: string) => `Impossible de trouver l’OT dans lequel vous vous êtes inscrit ${text}, recommencez tout le processus`,
            textTipologia: 'Typologies',
            tipologiaVacio: 'Sans typologies',
            textCargandoPreguntas: 'Chargement des questions',
            textCargandoTipologias: 'Chargement des typologies',
            textRegistroIncompleto: 'Avez-vous une inscription qui n’a pas été complétée, voulez-vous la télécharger?',
            validate: {
                formRegistro: {
                    ordenServicio: 'Saisir une commande de service / Demande de service',
                    ot: 'Sélectionnez un TO',
                    otDirectoCP: `Vous devez d’abord remplir la « liste de contrôle d’auto-vérification » ou la « discussion préalable à la tâche »`,
                    tipologia: 'Sélectionnez au moins une typologie',
                    empresa: 'Sélectionnez une entreprise',
                    delegacion: 'Sélectionnez une délégation',
                    actividad: 'Sélectionnez une activité',
                    cliente: 'Sélectionnez un client',
                    obra: 'Saisir une œuvre',
                    lugar: 'Entrer un lieu',
                    trabajoRealizar: 'Entrez un travail à faire',
                    jefeObra: 'Sélectionnez un gestionnaire de site',
                    usuarioRegistra: 'Sélectionner les registres d’utilisateurs'
                },
                formDinamico: {
                    preguntaNoRespondido: `Vous n'avez pas répondu au numéro de la question`,
                    preguntaNoEstadoEquipo: `Sélectionnez 'statut de l'équipe' dans le numéro de question`,
                    preguntaNoObservacion: `Entrez une 'observation' dans le numéro de question`,
                    preguntaNoAudio: `Enregistrer un 'audio' sur le numéro de question`,
                    preguntaNoPersonal: `Entrez 'personnel' dans le numéro de question`,
                    preguntaNoImagen: (count: number) => `Le nombre maximum d’images est de ${count} dans le numéro de question`,
                    preguntaImagen: `Entrez une image dans le numéro de la question`,
                    preguntaBloqueo: `Il n'est pas permis de continuer, en raison du manque de formation technique au numéro de question`,
                    preguntaNoImagenGrupal: `Saisir une image de groupe`,
                    preguntaNoObservacionGrupal: `Saisir une observation de groupe`,
                    preguntaBlockDevolverOrden: (numero: any) => `Si vous ne répondez pas au matériel nécessaire à la question numéro ${numero} vous ne pourrez pas vous inscrire`,
                    preguntaModuloAUT: 'Vous ne pouvez vous enregistrer que dans la "Liste d’autocontrôle" si vous êtes le seul membre de la brigade ; les enregistrements de groupe se font dans la "Pré-briefing"',
                    preguntaModuloCPT: 'Vous ne pouvez vous enregistrer que dans la "Pré-briefing" s’il y a plusieurs membres de la brigade ; les enregistrements individuels se font dans la "Liste d’autocontrôle"',
                    preguntaJefeTrabajo: 'Sélectionnez un Chef de travail dans la question numéro',
                    preguntaRiesgo: 'Sélectionnez un risque dans la question numéro',
                    preguntaMedidaPreventiva: 'Sélectionnez une Mesure Préventive dans la question numéro',
                    preguntaEPCS: 'Sélectionnez un Équipement de Protection Individuelle (EPI) dans la question numéro',
                    preguntaRecursoPreventivo: 'Sélectionnez la Ressource Préventive dans la question numéro',
                    preguntaFirmaJefeTrabajo: 'Saisissez la signature du Chef de travail dans la question numéro',
                    preguntaFirmaJefeTrabajoVacio: 'Dessinez une signature pour enregistrer',
                }
            }
        },
        comentario: {
            textComentario: 'Commentaire',
            titleModalAgregar: 'Ajouter un commentaire',
            titleModalList: 'Liste des commentaires',
            table: {
                autor: 'Auteur',
                puntuacion: 'Ponctuation',
                Comentario: 'Commentaire'
            },
            requiredAutochequeo: 'Vous devez d’abord examiner le PDF avant de saisir un commentaire',
            requiredCharlaPretarea: 'Vous devez d’abord examiner l’audio ou le PDF avant d’entrer un commentaire',
        }
    },
    moduloNotificacion: {
        textoNoLeido: 'Non lu',
        textoAcabaRecibirNotificacion: 'Vous venez de recevoir un nouveau commentaire dans le numéro d’enregistrement:',
        textoNuevo: 'Nouveau'
    },
    menu: {
        '010': {
            titulo: 'Inspections',
            descripcion: `Dossier d'inspection`
        },
        '020': {
            titulo: 'Inspections de la OPS',
            descripcion: 'Registre des inspections de la OPS'
        },
        '030': {
            titulo: `Liste d'auto-vérification`,
            descripcion: `Enregistrement de la liste d'auto-contrôle`
        },
        '031': {
            titulo: 'Discussion préalable à la tâche',
            descripcion: 'Enregistrement de la conférence préalable à la tâche'
        },
        '040': {
            titulo: 'Contrôle préventif',
            descripcion: 'Registre des questions de contrôle préventif'
        },
        '050': {
            titulo: 'RDI',
            descripcion: 'Journal des incidents de sécurité'
        },
        '060': {
            titulo: `Gestion de l'autocontrôle`,
            descripcion: `Gestion de l'autocontrôle`
        },
    },
    languages: [
        { code: 'es', name: 'Espagnol' },
        { code: 'en', name: 'Anglais' },
        { code: 'it', name: 'Italien' },
        { code: 'fr', name: 'Français' },
        { code: 'pt', name: 'Portugais' },
    ]
}